<template>
  <div class="fc-map__page -with-header">
    <div class="fc-map__page-header">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-btn icon @click="selectNextItem(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu offset-y min-width="100" max-height="200">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <h1 class="title">
                  {{ item.name }}
                </h1>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="listItem in items"
                :key="listItem.id"
                :class="{
                  'v-item--active v-list-item--active': listItem.id === id,
                }"
                @click="selectItem(listItem)"
              >
                <v-list-item-title>
                  {{ listItem.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="selectNextItem(+1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div cols="auto">
          <div class="d-flex align-center">
            <item-alert :item="item" mode="tooltip" left />
            <v-btn icon @click="centerItem">
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'main' }" icon exact>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-tabs
      :value="tab"
      grow
      dark
      show-arrows
      background-color="grey darken-2"
      class="fc-map__page-tabs-container"
      @change="setTab"
    >
      <v-tabs-slider />
      <v-tab key="main">
        <span class="hidden-xs-only">{{ $t('scada.title') }}</span>
        <v-icon class="hidden-sm-and-up"> mdi-rotate-left </v-icon>
      </v-tab>
      <v-tab-item key="main">
        <div class="fc-map__page-body">
          <div class="body-1">
            <v-row>
              <v-col
                v-for="(line, index) in item.data.line"
                :key="index"
                sm="6"
                cols="12"
              >
                <v-card class="my-3 elevation-1" style="margin-bottom: -30px">
                  <v-card-title>
                    {{ $t('scada.pump') + " " + (index + 1) }}

                    <v-chip v-if="status(line)" small class="pl-2 pr-2 ml-2" :color="line.step < 40 ? 'warning' : 'accent'">{{ status(line) }}</v-chip>
                    <v-spacer />

                    <div cols="auto">
                      <div class="d-flex align-end">
                        <item-alert :item="line" mode="tooltip" left />
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="guage-chart">
                          <GaugeChart :pump="line" :index="index" />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="d-flex fill-height align-center justify-center"
                      >
                        <v-btn
                          class="ma-2"
                          flat
                          :disabled="!line.on"
                          color="error"
                          >{{ line.on ? $t('scada.pumpOn') : $t('scada.pumpOff') }}</v-btn
                        >
                        <v-btn color="primary" class="ma-2"
                          >{{ line.pt }} {{ $t('form.pressureSuffix') }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
    <div class="fc-map__page-footer"></div>
  </div>
</template>

<style>
.info-block {
  font-size: 1.2em;
  text-align: center;
  background: red;
  font-weight: bold;
  color: white;
  background: rgb(35 40 185);
  background: linear-gradient(47deg, rgb(130 169 255) 0%, rgb(0 39 255) 100%);
  border-radius: 5px;
}
.guage-chart .highcharts-wrapper {
  margin-top: -12px;
}
.guage-chart .highcharts-container {
  height: 150px !important;
}
.guage-chart svg.highcharts-root {
  position: absolute;
}
</style>

<script>
import ItemAlert from "~/components/map/ItemAlert";
import GaugeChart from "~/components/chart/GaugeChart";

import generalFormMixin from "~/components/mixins/generalFormMixin";

export default {
  components: {
    ItemAlert,
    GaugeChart
  },
  mixins: [generalFormMixin],
  computed: {
    id() {
      return Number(this.$route.query.id) || 0;
    },
    tab() {
      return Number(this.$route.query.tab) || 0;
    },
    items() {
      return this.$store.getters["pumps/items"];
    },
    item() {
      return this.items.find((item) => item.id === this.id);
    },
    
  },
  watch: {
    id(value) {
      if (!value) {
        // this.$bus.$emit('map.center')
      } else {
        this.centerItem();
      }
    },
  },
  mounted() {
    if (this.id) {
      this.centerItem();
    } else {
      this.$bus.$emit("map.center");
    }
  },
  beforeDestroy() {
    this._timeout && clearTimeout(this._timeout);
  },
  methods: {
    status(line) {
      if (line.step == '10') {
        return "Начальное заполнение";
      } else if (line.step == '20') {
        return "Заполнение контура";
      } else if (line.step == '21') {
        return "Закрытие клапанов";
      } else if (line.step == '30') {
        return "Набор давления";
      } else if (line.step == '40') {
        return "Работа";
      } else {
        return null;
      }
    },
    selectItem(item) {
      this.$router.push({
        name: "scada",
        query: { id: item.id, tab: this.tab },
      });
    },
    setTab(tab) {
      this.$router.push({ name: "scada", query: { id: this.id, tab } });
    },
    selectNextItem(direction) {
      if (!this.id || !this.items.length) {
        return false;
      }
      let itemIndex = this.items.findIndex((item) => item.id === this.id);
      itemIndex = (itemIndex || 0) + direction;
      while (itemIndex < 0) {
        itemIndex += this.items.length;
      }
      while (itemIndex >= this.items.length) {
        itemIndex -= this.items.length;
      }
      this.selectItem(this.items[itemIndex]);
    },
    centerItem() {
      if (this.item && this.item.displaySettings) {
        if (this.item.displaySettings.type === "pump" && this.item.coords) {
          this.$bus.$emit("map.center", this.item.coords);
        }
      }
    },
  },
};
</script>

