import Vue from 'vue'
import { dragscroll } from 'vue-dragscroll'
import vuetify from '~/plugins/vuetify'
import App from '~/App'
import router from '~/router'
import store from '~/store'
import configApi from '~/config/api'
import configSettings from '~/config/settings'
import NodeResize from '~/directives/NodeResize'
import '~/plugins/bus'
import '~/plugins/i18n'
import '~/plugins/snack'
import '~/plugins/swal'
import '~/plugins/globalComponents'
import 'leaflet/dist/leaflet.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '~/assets/main.scss'
import clientStorage from '~/clientStorage'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import { Chart } from 'highcharts-vue'
import { VueMaskDirective } from 'v-mask'
import { GChart } from 'vue-google-charts'

HighchartsMore(Highcharts)
HighchartsSolidGauge(Highcharts)

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}

Vue.config.productionTip = false
Vue.directive('NodeResize', NodeResize)
Vue.directive('dragscroll', dragscroll)
Vue.component('highcharts', Chart)
Vue.component('gcharts', GChart)
Vue.directive('mask', VueMaskDirective);

Promise.resolve()
  .then(() => {
    let baseURL = clientStorage.get('api_base_url')
    if (!configApi.baseURLS.includes(baseURL)) {
      baseURL = configApi.baseURLS[0]
    }
    return store.dispatch('fetch/setAxiosDefaults', {baseURL})
  })
  .then(() => {
    const token = clientStorage.get('auth_token')
    if (token) {
      return Promise.resolve()
        .then(() => store.dispatch('auth/fetch', token))
        .catch((error) => {
          console.log(error)
          return store.dispatch('auth/logout')
        })
    }
  })
  .then(() => {
    return store.dispatch('auth/setSnapshotToken', clientStorage.get('snapshot_token'))
  })
  .then(() => {
    try {
      const settings = clientStorage.get(configSettings.cookieName)
      if (settings) {
        return store.dispatch('ux/updateSettings', settings)
      }
    } catch (error) {
      console.log('Failed to parse settings cookie', error)
    }
  })
  .then(() => store.dispatch('map/restoreFromSettings', (store.state.ux.settings || {}).map))
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
