<template>
  <div v-if="showActual" class="fc-map__page-body" style="overflow: unset">
    <div class="body-1">
      <v-card-text>
      <div class="title mb-2">{{ this.$t("monitoring.actualData") }}</div>
      <v-row style="margin-top: 0;">
        <v-col style="padding-top: 0; padding-bottom: 0;">{{ this.$t("monitoring.updated") }}: {{ this.data.date }} </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            {{ $t("form.temperature") }}: {{ this.data.Temperature }}
            {{ $t("form.temperatureSuffix") }}
          </div>
          <div>
            {{ this.$t('meteosColumns.moisture') }}: {{ this.data["Air Rh"]
            }}{{ $t("form.watermarkSuffix") }}
          </div>
          <div>
            {{ $t("charts.dew_point") }}: {{ this.data.Dewpoint }}
            {{ $t("form.temperatureSuffix") }}
          </div>
        </v-col>
        <v-col>
          <div>
            {{ this.$t('meteosColumns.windspeed') }}: {{ this.data["Wind Speed"] }}
            {{ this.$t("charts.units.ms") }}
          </div>
          <div>
            {{ this.$t('meteosColumns.windspeedmax') }} {{ this.data["Wind Speed Max"] }}
            {{ this.$t("charts.units.ms") }}
          </div>
          <div>{{ this.$t('meteosColumns.winddirection2') }}: {{ $t('compass.'+this.sides[this.data["Wind Direction"]]) }}</div>
        </v-col>
        <v-col>
            <div>{{ this.$t('meteosColumns.rain') }}</div>
            <div>{{ this.$t('meteosColumns.in1H') }}: {{ this.data["Rain 1H"] }}</div>
            <div>{{ this.$t('meteosColumns.in12H') }}: {{ this.data["Rain 12H"] }}</div>
            <!-- <div>С полуночи: {{ this.data["Rain midnight"] }}</div> -->
        </v-col>
      </v-row>
    </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sides: {
        'Севере': 'N', 
        'ССВ': 'NNE', 
        'СВ': 'NE', 
        'ВСВ': 'ENE', 
        'Восток': 'E', 
        'ВЮВ': 'ESE', 
        'ЮВ': 'SE', 
        'ЮЮВ': 'SSE', 
        'Юг': 'S', 
        'ЮЮЗ': 'SSW', 
        'ЮЗ': 'SW', 
        'ЗЮЗ': 'WSW', 
        'Запад': 'W', 
        'ЗСЗ': 'WNW', 
        'СЗ': 'NW', 
        'ССЗ': 'NNW'
    }
    }
  },
  computed: {
    showActual() {
      return this.data && !_.isEmpty(this.data);
    },
    timeOffset() {
      let timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      return timeOffset - 3
    }
  },
};
</script>