<template>
  <div class="fc-map__page -full-height -outer-corners meteo-tabs">
    <div class="fc-map__page-body">
      <table class="fc-map__table table_meteo_summary_data mt-3">
        <thead>
          <tr class="fc-map__table-row">
            <th v-for="column in columns" :key="column.key">
              {{ $t("instartColumns." + column.key) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in sortedItems"
            :key="item.id"
            class="fc-map__table-row"
          >
            <td v-for="column in columns" :key="column.key">
              <template v-if="column.key === 'name'">
                <router-link
                  :to="{
                    name: 'instart',
                    query: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>
              <template v-if="column.key === 'frequency'">
                {{ item.data["frequency"] }} {{ $t("form.hertzSuffix") }}
              </template>
              <template v-if="column.key === 'voltage'">
                {{ item.data["voltage"] }}
              </template>
              <template v-if="column.key === 'amperage'">
                {{ item.data["amperage"] }}
              </template>
              <template v-if="column.key === 'power'">
                {{ item.data["power"] }}
              </template>
              <template v-if="column.key === 'state'">
                {{ item.data["state"] }}

                <!-- <item-icon-controls v-model="instance[item.id]" >
                  <v-col v-if="showSave(item)" class="d-flex flex-no-grow align-center">
                    <v-btn :loading="loading[item.id]" small @click="apply(instance[item.id], item)">{{ $t('actions.apply') }}</v-btn>
                    <v-btn :loading="loading[item.id]" small text @click="cancel(item)">{{ $t('actions.cancel') }}</v-btn>
                  </v-col>
                </item-icon-controls> -->
              </template>
              <template v-if="column.key === 'monitoring'">
                {{ item.data["monitoring"] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import ItemIconControls from '~/components/map/InstartIconControls'
import configControls from '~/config/controls3'

export default {
  // components: {ItemIconControls},
  data() {
    return {
      columns: [
        { key: "name", flex: "0 0 100px" },
        { key: "frequency", flex: "0 0 70px" },
        { key: "voltage", flex: "0 0 70px" },
        { key: "amperage", flex: "0 0 70px" },
        { key: "power", flex: "0 0 70px" },
        { key: "state", flex: "0 0 70px" },
        { key: "monitoring", flex: "0 0 100px" },
      ],
      instance: {},
    };
  },
  computed: {
    controls () { return configControls },
    items() {
      return this.$store.getters["instart/items"];
    },
    instanceTrigger () { 
      // console.log(JSON.stringify(this.items))
      return JSON.stringify(this.items.map(item => ({id: item.id, ...item.deviceSettings.workPlan}))) },
    sortedItems() {
      return [...this.items];
    },
  },
  watch: {
    instanceTrigger: {
      immediate: true,
      handler () {
        const instance = {}
        const loading = {}
        const restoreValue = (ar1, ar2, key) => {
          if (_.isObject(ar1) && _.isObject(ar2)) { return ar1[key] || ar2[key] }
          if (_.isObject(ar1)) { return ar1[key] }
          if (_.isObject(ar2)) { return ar2[key] }
        }
        this.items.forEach((item) => {
          // console.log(item)
          instance[item.id] = this.controls.reduce((out, control) => {
            out[control.key] = restoreValue(this.instance[item.id], item.deviceSettings.workPlan, control.key)
            return out
          }, {})
          loading[item.id] = !!this.loading[item.id]
        })

        this.instance = instance
        this.loading = loading
      }
    }
  },
  methods: {
    // showSave (item) {
    //   return !!Object.keys(this.instance[item.id]).find((key) => {
    //     console.log(this.instance)
    //     return this.instance[item.id][key] !== item.deviceSettings.workPlan[key]
    //   })

    //   // return false
    // },
    apply(instance, item) {
      this.loading[item.id] = true
    },
    cancel(item) {
      const instance = this.controls.reduce((out, control) => {
        out[control.key] = item.deviceSettings.workPlan[control.key]
        return out
      }, {})
      this.instance[item.id] = instance

    },
  }
};
</script>