<template>
    <div class="fc-map__page-body">
        <table class="fc-map__table table_meteo_summary_data mt-3">
            <thead>
                <tr class="fc-map__table-row">
                    <th v-for="column in columns" :key="column.key">
                        {{ $t('meteosColumns.' + column.key) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in sortedItems" :key="item.id" class="fc-map__table-row">
                    <td v-for="column in columns" :key="column.key">
                        <template v-if="column.key === 'name'">
                            <router-link :to="{ name: item.displaySettings.type, query: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </template>
                        <template v-if="column.key === 'temperature'">
                            {{ item.actual['Temperature'] }}°C
                        </template>
                        <template v-if="column.key === 'airrh'">
                            {{ item.actual['Air Rh'] }}%
                        </template>
                        <template v-if="column.key === 'dewpoint'">
                            {{ item.actual['Dewpoint'] }}°C
                        </template>
                        <template v-if="column.key === 'windspeed'">
                            <div style="white-space: nowrap;">{{ item.actual['Wind Speed'] }} {{ $t('charts.units.ms') }}</div>
                        </template>
                        <template v-if="column.key === 'windspeedmax'">
                            <div style="white-space: nowrap;">{{ item.actual['Wind Speed Max'] }} {{ $t('charts.units.ms') }}</div>
                        </template>
                        <template v-if="column.key === 'winddirection'">
                            <div style="white-space: nowrap;">{{ $t('compass.'+sides[item.actual['Wind Direction']]) }}</div>
                        </template>
                        <template v-if="column.key === 'rain1h'">
                            {{ item.actual['Rain 1H'] }}
                        </template>
                        <template v-if="column.key === 'date'">
                            {{ item.actual['date'] }}
                        </template>
                    </td>

                </tr>
            </tbody>
        </table>
        <!-- <scrollable-table :head-size="40">
            <template #head>
                <div class="fc-map-table__row fc-map-table__row--header">
                    <div v-for="column in columns" :key="column.key" :style="{
                        flex: column.flex
                    }" :class="'fc-map-table__cell--' + column.key" class="fc-map-table__cell fc-map-table__cell--header">
                        <div v-if="column.key !== 'summary'" class="fc-map-table__header-link">
                            {{ $t('meteosColumns.' + column.key) }}
                        </div>
                    </div>
                </div>
            </template>
            <template #body>
                <div v-for="item in sortedItems" :key="item.id" class="fc-map-table__row">
                    <div v-for="column in columns" :key="column.key" :style="{
                        flex: column.flex
                    }" :class="'fc-map-table__cell--' + column.key" class="fc-map-table__cell">
                        <template v-if="column.key === 'name'">
                            <router-link :to="{ name: item.displaySettings.type, query: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </template>
                        <template v-if="column.key === 'temperature'">
                            {{ item.actual['Temperature'] }}°C
                        </template>
                        <template v-if="column.key === 'airrh'">
                            {{ item.actual['Air Rh'] }}%
                        </template>
                        <template v-if="column.key === 'dewpoint'">
                            {{ item.actual['Dewpoint'] }}°C
                        </template>
                        <template v-if="column.key === 'windspeed'">
                            {{ item.actual['Wind Speed'] }} {{ $t('charts.units.ms') }}
                        </template>
                        <template v-if="column.key === 'windspeedmax'">
                            {{ item.actual['Wind Speed Max'] }} {{ $t('charts.units.ms') }}
                        </template>
                        <template v-if="column.key === 'date'">
                            {{ item.actual['date'] }}
                        </template>
                    </div>
                </div>
            </template>
        </scrollable-table> -->
    </div>
</template>

<style>
.table_meteo_summary_data {
    thead>tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .09);
    }

    tbody>tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .05);
    }

    th, td {
        font-size: 14px;
        padding: 6px;
        text-align: center;

        &:first-child {
            text-align: left;
            padding-left: 10px;
        }
    }

}
</style>
<script>

import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import generalFormMixin from '~/components/mixins/generalFormMixin'
// import ScrollableTable from "@/components/utils/ScrollableTable";

export default {
    components: {
        // ScrollableTable,
    },
    mixins: [dateTimeFormat, generalFormMixin],
    data() {
        return {
            sides: {
                'Север': 'N', 
                'ССВ': 'NNE', 
                'СВ': 'NE', 
                'ВСВ': 'ENE', 
                'Восток': 'E', 
                'ВЮВ': 'ESE', 
                'ЮВ': 'SE', 
                'ЮЮВ': 'SSE', 
                'Юг': 'S', 
                'ЮЮЗ': 'SSW', 
                'ЮЗ': 'SW', 
                'ЗЮЗ': 'WSW', 
                'Запад': 'W', 
                'ЗСЗ': 'WNW', 
                'СЗ': 'NW', 
                'ССЗ': 'NNW'
            },
            columns: [
                { key: 'name', flex: '0 0 100px' },
                { key: 'temperature', flex: '0 0 70px' },
                { key: 'airrh', flex: '0 0 90px' },
                { key: 'dewpoint', flex: '0 0 70px' },
                { key: 'windspeed', flex: '0 0 85px' },
                { key: 'windspeedmax', flex: '0 0 85px' },
                { key: 'winddirection', flex: '0 0 85px' },
                { key: 'rain1h', flex: '0 0 85px' },
                { key: 'date', flex: '0 0 80px' },
            ],
            instance: {},
            
        }
    },
    computed: {
        deviceStatus() { return this.item.deviceStatus || {} },
        items() { return this.$store.getters['meteos/items'] },
        sortedItems() {
            return [...this.items]
        }
    },
    watch: {
        instanceTrigger: {
            immediate: true,
            handler() {
                const instance = {}
                const loading = {}


                this.instance = instance
                this.loading = loading
            }
        }
    },
    beforeDestroy() {
        this._timeout && clearTimeout(this._timeout)
    },
    methods: {

    }
}
</script>
