<template>
  <div class="fc-map__page -with-header">
    <div class="fc-map__page-header">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-btn icon @click="selectNextItem(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu offset-y min-width="100" max-height="200">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <h1 class="title">
                  {{ item.name }}
                </h1>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="listItem in items"
                :key="listItem.id"
                :class="{
                  'v-item--active v-list-item--active': listItem.id === id,
                }"
                @click="selectItem(listItem)"
              >
                <v-list-item-title>
                  {{ listItem.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="selectNextItem(+1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div cols="auto">
          <div class="d-flex align-center">
            <v-btn icon @click="centerItem">
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
            <v-btn icon exact>
              <item-online :item="item" />
            </v-btn>
            <v-btn :to="{ name: 'main' }" icon exact>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-tabs
      :value="tab"
      grow
      dark
      show-arrows
      background-color="grey darken-2"
      class="fc-map__page-tabs-container"
      @change="setTab"
    >
      <v-tabs-slider />
      <v-tab key="main">
        <span class="hidden-xs-only">{{ $t("instart.title") }}</span>
        <v-icon class="hidden-sm-and-up"> mdi-rotate-left </v-icon>
      </v-tab>
      <v-tab-item key="main">
        <div class="fc-map__page-body">
          <div class="body-1">
            <v-row>
              <v-col cols="12" sm="5">
                <div class="guage-chart">
                  <GaugeChart :pump="item.data" />
                </div>
              </v-col>
              <v-col cols="12" sm="7">
                <div class="title">
                  {{ $t("instart.status." + item.deviceStatus.move) }}
                </div>
                <div class="subtitle">
                  {{ item.data.monitoring }}
                </div>
                <div class="mt-5">
                  <v-spacer />
                </div>

                <div class="instart-fields">
                  <fields v-model="item.data" :fields="fields" />
                </div>
                <v-row>
                  <v-col cols="6">
                    <field-control
                      v-model="item.data.frequency"
                      :field="fieldsRev.frequency"
                    />
                  </v-col>
                  <v-col cols="6" class="d-flex flex-wrap justify-end">
                    <v-btn
                      class="ma-2 apply"
                      flat
                      :disabled="item.deviceStatus.move === 'stop'"
                      :loading="loading"
                      color="primary"
                      @click="save"
                    >
                      {{ $t("actions.apply") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row class="mt-3">
            <v-col class="d-flex flex-wrap justify-end">
              <!-- <v-btn
                class="ma-2"
                flat
                :disabled="item.deviceStatus.move === 'stop'"
                :loading="loading"
                color="primary"
                @click="save"
              >
                {{ $t("actions.apply") }}
              </v-btn> -->

              <v-btn
                class="ma-2"
                flat
                :disabled="item.deviceStatus.move !== 'stop'"
                :loading="loading"
                color="success"
                @click="action('start')"
              >
                {{
                  item.deviceStatus.move !== "stop"
                    ? $t("instart.turnedOn")
                    : $t("instart.start")
                }}
              </v-btn>

              <v-btn
                class="ma-2"
                flat
                :disabled="item.deviceStatus.move === 'stop'"
                :loading="loading"
                color="error"
                @click="action('stop')"
              >
                {{
                  item.deviceStatus.move === "stop"
                    ? $t("instart.turnedOff")
                    : $t("instart.stop")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs>
    <div class="fc-map__page-footer"></div>
  </div>
</template>

<style>
.guage-chart {
  padding-top: 15px;
}
</style>

<script>
import GaugeChart from "~/components/chart/GaugeChart";
import configFields from "~/config/fields";
import generalFormMixin from "~/components/mixins/generalFormMixin";
import ItemOnline from '~/components/map/ItemOnline'

export default {
  components: {
    GaugeChart,
    ItemOnline
  },
  mixins: [generalFormMixin],
  data() {
    return {
      instance: {},
      editingInstance: false,
    };
  },
  computed: {
    id() {
      return Number(this.$route.query.id) || 0;
    },
    tab() {
      return Number(this.$route.query.tab) || 0;
    },
    items() {
      return this.$store.getters["instart/items"];
    },
    item() {
      return this.items.find((item) => item.id === this.id);
    },
    fields() {
      return configFields.instart;
    },
    fieldsRev() {
      return this.fields.reduce((out, field) => {
        out[field.key] = field;
        return out;
      }, {});
    },
    updateInstanceTrigger() {
      return JSON.stringify({
        id: this.id,
        item: this.item,
        editingInstance: this.editingInstance,
      });
    },
  },
  watch: {
    id(value) {
      if (!value) {
        this.$bus.$emit('map.center')
      } else {
        this.centerItem();
      }
    },
  },
  mounted() {
    if (this.id) {
      this.centerItem();
    } else {
      this.$bus.$emit("map.center");
    }
  },
  beforeDestroy() {
    this._timeout && clearTimeout(this._timeout);
  },
  methods: {
    // prepareInstance (item) {
    //   if (item) {
    //     const instance = _.cloneDeep(item)

    //     return instance
    //   } else {
    //     return []
    //   }
    // },
    setTab(tab) {
      this.$router.push({ name: "instart", query: { id: this.id, tab } });
    },
    centerItem() {
      if (this.item && this.item.displaySettings) {
        if (this.item.displaySettings.type === "instart" && this.item.coords) {
          this.$bus.$emit("map.center", this.item.coords);
        }
      }
    },
    action(state) {
      let text = this.$t("success.confirmStartFreq");

      if (state == "stop") {
        text = this.$t("success.confirmStopFreq");
      }

      this.$swal({
        icon: "warning",
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t("actions.apply"),
        cancelButtonText: this.$t("actions.cancel"),
        focusConfirm: false,
      }).then((result) => {
        if (result.value) {
          this.setLoading(true);

          let path = "instartAction." + state;

          Promise.resolve()
            .then(() =>
              this.$store.dispatch(
                "fetch/fetch",
                { path: path, data: { id: this.id } },
                { root: true }
              )
            )
            .then(
              () =>
                new Promise((resolve) => {
                  this._timeout && clearTimeout(this._timeout);
                  this._timeout = setTimeout(resolve, 2000);
                })
            )
            // .then(() => this.$store.dispatch("rtu/fetch"))
            .then(() => {
              this.$swal({ icon: "success", text: this.$t("success.cmdSent") });
              // callback && callback();
            })
            .catch(this.handleError)
            .finally(() => {
              this.setLoading(false);
            });
        }
      });
    },
    save() {
      this.setLoading(true);
      const data = this.prepareFormSubmitData(this.item.data, this.fields);

      const command = this.prepareCommand(data);
      console.log(command);
      command.id = this.item.id;

      Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: "instartAction.cmd",
            data: command,
          })
        )
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error);
          }
        })
        .then(
          () =>
            new Promise((resolve) => {
              this._timeout && clearTimeout(this._timeout);
              this._timeout = setTimeout(resolve, 500);
            })
        )
        .then(() => {
          this.$snack({ type: "success", message: this.$t("success.cmdSent") });
          // this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });
    },
    prepareCommand(data) {
      const command = {};
      command.frequency = parseFloat(data.frequency).toFixed(2);
      return command;
    },
  },
};
</script>

<style>
.instart-fields .col:nth-child(3) {
  display: none;
}
.v-btn.apply {
  height: 100% !important;
  width: 100%;
  margin: 0 !important;
  padding: 0;
}
</style>