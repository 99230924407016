<template>
  <div class="fc-map__page-body" style="overflow: unset">
    <actual-data :data="chartData.actual" />

    <forecast-data :id="id" :data="chartData['Weather Forecast']" />

    <div class="body-1">
      <v-card-text>
        <div class="title mb-2 mt-0">{{ this.$t("monitoring.archiveData") }}</div>
        <v-row style="position: relative">
          <v-col>
            <fields v-model="filterData" :fields="filterFields" />
            <div class="hintDays small">{{ this.$t("monitoring.numberOf") }} {{ this.$t("monitoring.days") }}: {{ this.days }}</div>
          </v-col>
          <v-col cols="auto" class="mt-3">
            <v-btn :loading="loading" color="primary" @click="find">{{
              $t("actions.find")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div class="fc-map__page-body pb-3">
      <v-card-text v-if="!hasData && !loading" :loading="loading">
        <v-alert type="info" outlined>
          {{ $t("success.noMeteoData") }}
        </v-alert>
      </v-card-text>
      <template v-else>
        <div v-if="chartData.Watermarks">
          <WatermarkChart
            :data="chartData.Watermarks"
            :dates="chartData.dates"
            :provider="chartData.prov"
            :reversed="chartData.prov === 1"
            :soil="chartData.Soil"
          />
          <div>
            <v-card-text v-if="editingInstance">
              <table class="fc-map__table">
                <tbody>
                  <tr class="fc-map__table-row">
                    <th>{{ this.$t('charts.sensor_calibration') }}</th>
                    <td class="fc-map__table-cell">
                      <field-control
                        v-model="instance['Soil'].wilting_point"
                        :disabled="!editingInstance"
                        :field="WiltingPointField"
                      />
                    </td>

                    <td class="fc-map__table-cell">
                      <field-control
                        v-model="instance['Soil'].start_watering"
                        :disabled="!editingInstance"
                        :field="StartWateringField"
                      />
                    </td>

                    <td class="fc-map__table-cell">
                      <field-control
                        v-model="instance['Soil'].stop_watering"
                        :disabled="!editingInstance"
                        :field="StopWateringField"
                      />
                    </td>

                    <!-- <td class="fc-map__table-cell">
                    <field-control v-model="instance['Soil'].smallest_field_capacity" :disabled="!editingInstance" :field="SmallestCapacityField" />
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </v-card-text>
            <v-row class="mb-3" :loading="loading">
              <v-col class="d-flex flex-wrap justify-end">
                <template v-if="editingInstance">
                  <v-btn :loading="loading" text @click="cancel">{{
                    $t("actions.cancel")
                  }}</v-btn>
                  <v-btn :loading="loading" color="primary" @click="save">{{
                    $t("actions.save")
                  }}</v-btn>
                </template>
                <template v-else>
                  <!-- <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn> -->
                  <v-btn text @click="start">{{
                    $t("actions.editWatermarkSensor")
                  }}</v-btn>
                </template>
              </v-col>
            </v-row>
          </div>
        </div>

        <summary-data :data="chartData" />
        <sta-gdd-data :data="chartData" />
        <p></p>

        <AirTemperatureChart :data="chartData" />

        <PrecipitationChart :data="chartData" />
        <p></p>
        <p></p>
        <DewPointChart :data="chartData" />

        <AirRhChart :data="chartData" />

        <SolarRadiationChart :data="chartData" />

        <WindSpeedChart :data="chartData" />

        <WindChart :data="chartData" />

        <TableData :data="chartData" />
      </template>
    </div>
  </div>
</template>

<style>
.hintDays {
  position: absolute;
  bottom: 9px;
  font-size: .9em;
}
</style>

<script>
import configFields from "~/config/fields";
import generalFormMixin from "~/components/mixins/generalFormMixin";
import ActualData from "~/components/chart/ActualData";
import SummaryData from "~/components/chart/SummaryData";
import StaGddData from "~/components/chart/StaGddData";
import ForecastData from "~/components/chart/ForecastData";
import WatermarkChart from "./WatermarkChart";
import AirTemperatureChart from "./AirTemperature2Chart";
import DewPointChart from "./DewPointChart";
import AirRhChart from "./AirRhChart";
import SolarRadiationChart from "./SolarRadiationChart";
import PrecipitationChart from "./PrecipitationChart";
import WindSpeedChart from "./WindSpeedChart";
import WindChart from "./WindChart";
import TableData from "./TableData";
import clientStorage from "~/clientStorage";
import moment from "moment";

export default {
  components: {
    ActualData,
    SummaryData,
    StaGddData,
    ForecastData,
    WatermarkChart,
    AirTemperatureChart,
    DewPointChart,
    AirRhChart,
    PrecipitationChart,
    SolarRadiationChart,
    WindChart,
    WindSpeedChart,
    TableData,
  },
  mixins: [generalFormMixin],
  props: ["id"],
  data() {
    return {
      loading: false,
      chartData: {},
      filterData: {
        from: null,
        to: null,
        period: null,
      },
      locale: {
        'Час': this.$t('filter.hour'),
        'День': this.$t('filter.day'),
      },
      instance: [],
      editingInstance: false,
    };
  },
  computed: {
    hasData() {
      return this.chartData["dates"] && !_.isEmpty(this.chartData["dates"]);
    },
    WiltingPointField() {
      return configFields.soil.find((field) => field.key === "wilting_point");
    },
    StartWateringField() {
      return configFields.soil.find((field) => field.key === "start_watering");
    },
    StopWateringField() {
      return configFields.soil.find((field) => field.key === "stop_watering");
    },
    SmallestCapacityField() {
      return configFields.soil.find(
        (field) => field.key === "smallest_field_capacity"
      );
    },
    fields() {
      return configFields.soil;
    },
    days() {
      return this.diffDays()
    },
    filterFields () { 
      return configFields.stationFilter.filter(field => {
        if (field.key == 'period') {
          field.dataSource.forEach((val, i) => {
            console.log(val)
            field.dataSource[i] = this.locale[val] ? this.locale[val] : val
          })

        }
        
        return field
      })
    },
    updateInstanceTrigger() {
      return JSON.stringify({ id: this.id, item: this.item });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.loadData();
      },
    },
    filterData: {
      immediate: true,
      handler() {
        this.diffDays();
      },
    }
  },
  methods: {
    prepareWatermarkInstance(item) {
      this.chartData = {};
      // console.log(item)
      if (item) {
        const instance = _.cloneDeep(item);
        this.chartData = item;

        return instance;
      } else {
        return [];
      }
    },
    diffDays() {
      return moment(this.filterData.to).diff(this.filterData.from, 'days')
    },
    find() {
      this.setLoading(true);
      const data = this.prepareFormSubmitData(
        this.filterData,
        this.filterFields
      );

      clientStorage.set("chart_from", this.filterData.from);
      clientStorage.set("chart_to", this.filterData.to);
      clientStorage.set("chart_period", this.filterData.period);

      return Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: "meteo.request",
            data: { id: this.id, data: data },
          })
        )
        .then((response) => {
          this.chartData = response.data;
          this.instance = this.prepareWatermarkInstance(this.chartData);
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });
    },
    loadData(force) {
      this.setLoading(true);

      this.chartData = {};

      const params = { id: this.id };

      if (
        clientStorage.get("chart_from") &&
        clientStorage.get("chart_to") &&
        clientStorage.get("chart_period")
      ) {
        const data = {
          from: clientStorage.get("chart_from"),
          to: clientStorage.get("chart_to"),
          period: clientStorage.get("chart_period"),
        };
        params.data = data;
      }

      return Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: "meteo.request",
            data: params,
            force,
          })
        )
        .then((response) => {
          this.chartData = response.data;

          this.filterData.from =
            clientStorage.get("chart_from") ??
            moment(
              this.chartData.dates[0] + "." + new Date().getFullYear(),
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");
          this.filterData.to =
            clientStorage.get("chart_to") ??
            moment(
              _.last(this.chartData.dates) + "." + new Date().getFullYear(),
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");

            this.filterData.period = clientStorage.get("chart_period") ?? "День";

            this.filterData.period = this.locale[this.filterData.period] ? this.locale[this.filterData.period] : this.filterData.period;

          this.instance = this.prepareWatermarkInstance(this.chartData);
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });
    },
    start() {
      this.editingInstance = true;
    },
    save() {
      this.setLoading(true);
      // const data = this.prepareWatermarkFormData(this.instance.soil)
      const data = this.instance["Soil"];
      Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: "meteo.update",
            data: { id: this.id, data },
          })
        )
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t("success.watermarkSettingsUpdated"),
          });
          this.cancel();
          this.loadData();
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });
    },
    cancel() {
      this.instance = this.prepareWatermarkInstance(this.chartData);
      this.editingInstance = false;
    },
  },
};
</script>
