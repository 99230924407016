<template>
  <div class="fc-map__page -with-header -full-width">
    <div class="fc-map__page-header">
      <v-row align="center">
        <v-col>
          <h1 class="title">{{ $t('menu.reports') }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn :to="{name: 'main'}" icon exact>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="fc-map__page-body"  style="overflow: unset">
      <div class="body-1">
        <v-card-text>
          <v-row>
            <v-col
              cols="10"
              >
              <fields v-model="instance" :fields="fields" />
            </v-col>
            <v-col
              cols="2"
              >
              <v-card-actions class="px-6 pb-4">
                <v-spacer />
                <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
                <!-- <v-btn :loading="loading" color="success" @click="exportToXls">XLS</v-btn> -->
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </div>
    <template>
      <div class="fc-map__page-body pb-3">
          <v-data-table
            :headers="headers"
            :items="items"
          >
            <template v-slot:item="{item}">
              <tr>
                <td v-for="header in headers" :key="header.value" nowrap>
                  <template>
                    {{ getItemValue(item, header) }}
                  </template>
                </td>
              </tr>
              
            </template>
            
            <template v-if="items" slot="body.append">
              <!-- <tr>
                <td>Итого</td>
                <td v-if="isAdmin"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td nowrap>{{ totals.ha }} Га</td>
                <td></td>
                <td nowrap>{{ totals.flow }} м³</td>
                <td></td>
              </tr> -->
              <tr>
                <td colspan="12" style="border-width:2px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="12" style="border-width:2px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.dryTrack') }}</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Общее время движения без воды']}}</td>

                <td colspan="4" style="border-right:1px solid;">{{ this.$t('report.areaDryTrack') }}</td>
                <td colspan="1" style="border-right:1px solid;" nowrap>{{totals['Площадь сухого следа']}} {{ this.$t('charts.units.ha') }}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.totalTravelTimeWithWater') }}</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Общее время движения с водой']}}</td>

                <td colspan="4" style="border-right:1px solid">{{ this.$t('report.areaWetTrackWater') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Площадь мокрого следа с водой']}} {{ this.$t('charts.units.ha') }}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.totalTravelTimeWithFert') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общее время движения с удобрениями']}}</td>

                <td colspan="4" style="border-right:1px solid">{{ this.$t('report.areaWetTrackFert') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Площадь мокрого следа с удобрениями']}} {{ this.$t('charts.units.ha') }}</td>
                
              </tr>
              <!-- <tr>
                <td colspan="4" style="border-right:1px solid"></td>
                <td colspan="3" style="border-right:1px solid">Общая площадь</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общая площадь']}}</td>
              </tr> -->
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.totalWater') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общий расход воды']}} {{ this.$t('charts.units.m3') }}</td>

                <td colspan="4" style="border-right:1px solid; font-weight: bold;">{{ this.$t('report.totalTraveled') }}</td>
                <td colspan="1" style="border-right:1px solid; font-weight: bold;" nowrap>{{totals['Общий пройденный путь']}}</td>
              </tr>
              <tr>
                <td colspan="9" style="border-width:1px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="9" style="border-width:1px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.serviceStop') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Остановка для обслуживания']}}</td>

                <td colspan="4" style="border-right:1px solid">{{ this.$t('report.moveStopped') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Движение прекращено']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.alignmentError') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Ошибка выравнивания']}}</td>

                <td colspan="4" style="border-right:1px solid">{{ this.$t('report.pressureRecovery') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Восстановление давления']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('report.lowPressureError') }}</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Ошибка низкого давления']}}</td>
                
                <td colspan="4" style="border-right:1px solid">{{ this.$t('report.pressureWaitingDelay') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Задержка ожидания давления']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">{{ this.$t('status.no_connection') }}</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Нет связи']}}</td>

                <td colspan="4" style="border-right:1px solid; font-weight: bold;">{{ this.$t('report.totalDowntime') }}</td>
                <td colspan="1" style="border-right:1px solid; font-weight: bold;" nowrap>{{totals['Общее время простоя']}}</td>
              </tr>
            </template>  
          </v-data-table>
          
        </div>
    </template>
    
    <div class="fc-map__page-footer" />
  </div>
</template>
<style>
.v-data-table tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>
<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'
// import ReportItems from '~/components/report/ReportItems'
import configFields from '~/config/fields'

export default {
  mixins: [generalFormMixin],
  data () {
    return {
      items: [],
      header: [],
      totals: [],
      locale: {
        'Движение вперед': this.$t('report.moveFwd'), 
        'Движение вперед с водой': this.$t('report.moveFwdWtr'), 
        'Движение вперед с удобрением': this.$t('report.moveFwdFrt'), 
        'Движение назад': this.$t('report.moveRev'),
        'Движение назад с водой': this.$t('report.moveRevWtr'), 
        'Движение назад с удобрением':  this.$t('report.moveRevFrt'),
        'Движение прекращено': this.$t('report.moveStopped'), 
        'Остановка': this.$t('report.stopped'), 
        'Ожидание': this.$t('report.waiting'), 
        'Мягкий барьер': this.$t('report.softBarrier'), 
        'Автоматическая остановка у барьера': this.$t('report.automaticStopBarrier'), 
        'Ошибка низкого давления': this.$t('report.lowPressureError'),
        'Ошибка цепи безопасности': this.$t('report.safetyError'), 
        'Ошибка выравнивания': this.$t('report.alignmentError'), 
        'Остановка для обслуживания': this.$t('report.serviceStop'), 
        'Восстановление давления': this.$t('report.pressureRecovery'),
        'Минимально допустимое давление': this.$t('report.minAllowPressure'),
        'Задержка ожидания давления': this.$t('report.pressureWaitingDelay'),
        'Мин.допустимое давление': this.$t('report.minAllowPressure'),
        'Ошибка GPS': this.$t('report.gpsError'),
        'Предупреждения': this.$t('report.someWarning'),
        'Движение прекращено с ошибкой': this.$t('report.stopWithError'),
        'Ошибка 19': this.$t('report.someError'),
        'Отсутствие напряжения': this.$t('report.noElectric'),
        'Нет связи': this.$t('status.no_connection')
      },
      dateLocale: {
        'часов': this.$t('report.hours')
      },
      instance: {
        date_from: null,
        date_to: null,
        time_from: null,
        time_to: null
      }
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    fields () {
      return configFields.reportFilter
      .filter(field => {
          if (field.key == 'status') {
          field.dataSource.forEach((val, i) => {
            console.log(val)
            field.dataSource[i] = this.locale[val] ? this.locale[val] : val
          })

        }
          if (!field.permission) { return true }
          return (this.$store.state.auth.user.permissions || []).includes(field.permission)
        })
    },
    headers () {
      var header = [
          {text: this.$t('report.pivot'), value: 'rtu'},
        ]

        if (this.isAdmin)
          header.push({text: this.$t('report.organization'), value: 'organization'})

        header.push(
          {text: this.$t('report.date'), value: 'date', units: ''},
          {text: this.$t('report.status'), value: 'status', units: ''},
          {text: this.$t('report.field'), value: 'field', units: ''},
          {text: this.$t('report.position'), value: 'position_u', units: 'м'},
          {text: this.$t('report.passed'), value: 'distance_u', units: ''},
          {text: this.$t('report.speed'), value: 'speed_u', units: ''},
          {text: this.$t('report.depth'), value: 'depth_u', units: ''},
          {text: this.$t('report.ha'), value: 'ha_u', units: ''},
          {text: this.$t('report.bar'), value: 'bar_u', units: ''},
          {text: this.$t('report.flow'), value: 'flow_u', units: ''},
          {text: this.$t('report.duration'), value: 'duration_human', units: ''}
          )
      return header
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    getItemValue (item, header) {
      const pathParts = (header.field || header.value || header.units || '').split('.')
      let result = item
      for (let i = 0; i < pathParts.length; i++) {
        if (result && Object.prototype.hasOwnProperty.call(result, pathParts[i])) {
          result = result[pathParts[i]]
          if (pathParts[i] == 'status') {
            result = this.locale[result] ? this.locale[result] : result
          }
          else if (pathParts[i] == 'depth_u') {
            result = result + ' ' + this.$t('charts.units.mm')
          }
          else if (pathParts[i] == 'bar_u') {
            result = result + ' ' + this.$t('charts.units.bar')
          }
          else if (pathParts[i] == 'flow_u') {
            result = result + ' ' + this.$t('charts.units.m3')
          }
          else if (pathParts[i] == 'ha_u') {
            result = result + ' ' + this.$t('charts.units.ha')
          }
          else if (pathParts[i] == 'duration_human') {
            result = result
            .replace(/день/, this.$t('report.day'))
            .replace(/дня\s/, this.$t('report.days'))
            .replace(/дня/, this.$t('report.days'))
            .replace(/дней\s/, this.$t('report.days2'))
            .replace(/дней/, this.$t('report.days2'))
            .replace(/часов\s/, this.$t('report.hours'))
            .replace(/часов/, this.$t('report.hours'))
            .replace(/часа\s/, this.$t('report.hours2'))
            .replace(/часа/, this.$t('report.hours2'))
            .replace(/час\s/, this.$t('report.hour'))
            .replace(/час$/, this.$t('report.hour'))
            .replace(/минут$/, this.$t('report.min'))
            .replace(/минут\s/, this.$t('report.min'))
            .replace(/минута\s/, this.$t('report.min'))
            .replace(/минуту\s/, this.$t('report.min'))
            .replace(/минуту$/, this.$t('report.min'))
            .replace(/минуты\s/, this.$t('report.min'))
            .replace(/минуты$/, this.$t('report.min'))
            .replace(/секунд$/, this.$t('report.sec'))
            .replace(/секунда$/, this.$t('report.sec'))
            .replace(/секунду$/, this.$t('report.sec'))
            .replace(/секунды$/, this.$t('report.sec'))
          }
        } else {
          return header.emptyPlaceholder || null
        }
      }
      return result || header.emptyPlaceholder
    },
    loadItems (force) {
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'newReport', force}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
          if (this.items?.length) {
            this.instance.from = moment(this.items[this.items.length - 1].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')
            this.instance.to = moment(this.items[0].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')

            
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'newReport', data}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    exportToXls () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'exportToXls', data}, {responseType: 'blob'}))
        .then((response) => {
          if (response.data) {
            const link = document.createElement('a');
            link.href = "https://api.fieldconnect.ru/reports/" + response.data
            link.setAttribute('download', response.data);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
<style>
  .v-application--is-ltr .v-list-item__action:first-child {margin-right: 10px}
</style>