<template>
  <div class="fc-map__container">
    <l-map
      ref="map"
      :center="center"
      :zoom="zoom"
      :style="{width: '100%', height: '100%'}"
      @update:center="setCenter"
      @update:zoom="setZoom"
      @click="handleMouseClick"
    >
      <l-tile-layer v-bind="providerData" :key="providerData.id" />
      <v-locatecontrol/>
      <template v-if="map">
        <template v-if="!hideWaterMeters">
          <station-item
            v-for="stationItem in stationItems"
            :key="stationItem.id"
            :item="stationItem"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === stationItem.id.toString()"
            @click="selectItem(stationItem)"
          />
        </template>
        <template v-if="!hideMeteoStations">
          <meteo-item
            v-for="meteoItem in meteosItems"
            :key="meteoItem.id"
            :item="meteoItem"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === meteoItem.id.toString()"
            @click="selectItem(meteoItem)"
          />
        </template>
        <template v-if="!hidePivots && items.length">
          <map-item
            v-for="item in items"
            :key="item.id"
            :item="item"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === item.id.toString()"
            :editing="editingItemId === item.id"
            @click="selectItem(item)"
          />
        </template>
        <template v-if="!hideFields">
          <field-item
            v-for="fieldItem in fieldItems"
            :key="fieldItem.id"
            :item="fieldItem"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === fieldItem.id.toString()"
            @click="selectItem(fieldItem)"
          />
        </template>
        <template>
          <pump-item
            v-for="pumpItem in pumpItems"
            :key="pumpItem.id"
            :item="pumpItem"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === pumpItem.id.toString()"
            @click="selectItem(pumpItem)"
          />
        </template>
        <template>
          <instart-item
            v-for="instartItem in instartItems"
            :key="instartItem.id"
            :item="instartItem"
            :map="map"
            :zoom="zoom"
            :selected="selectedItemId.toString() === instartItem.id.toString()"
            @click="selectItem(instartItem)"
          />
        </template>
      </template>
    </l-map>
  </div>
</template>
<style scoped>
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
</style>
<script>
import geometryUtil from 'leaflet-geometryutil'
import Vue2LeafletLocatecontrol from './Vue2LeafletLocatecontrol'
import {LMap, LTileLayer} from 'vue2-leaflet'
import MapItem from './MapItem'
import StationItem from './StationItem'
import MeteoItem from './MeteoItem'
import FieldItem from './FieldItem'
import PumpItem from './PumpItem'
import InstartItem from './InstartItem'

export default {
  components: {LMap, LTileLayer, MapItem, StationItem, MeteoItem, FieldItem, PumpItem, InstartItem, 'v-locatecontrol': Vue2LeafletLocatecontrol},
  data () {
    return {
      map: null
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    center () { return this.$store.state.map.center },
    zoom () { return this.$store.state.map.zoom },
    provider () { return this.$store.state.map.provider },
    providerData () { return this.$store.getters['map/provider'] },
    items () { return this.$store.state.rtu.items },
    stationItems () { return this.$store.state.station.items },
    pumpItems () { return this.$store.state.pumps.items },
    instartItems () { return this.$store.state.instart.items },
    meteosItems () { return this.$store.state.meteos.items },
    fieldItems () { return this.$store.state.field.items },
    rtuAutofocusTrigger () {
      if (!this.items.length) {
        return false
      }
      
      return JSON.stringify(this.items.reduce((out, item) => {
        if (item.displaySettings && item.displaySettings.type === 'circle' && item.displaySettings.center) {
          out.push(_.pick(item.displaySettings, 'center', 'radius'))
        }
        return out
      }, []))
    },
    selectedItemId () { return this.$route.query.id || '' },
    editingItemId () { return this.$store.state.rtu.editingItemId },
    hideFields () { return this.$store.state.map.hideFields },
    hideTitles () { return this.$store.state.map.hideTitles },
    hidePivots () { return this.$store.state.map.hidePivots },
    hideMeteoStations () { return this.$store.state.map.hideMeteoStations },
    hideWaterMeters () { return this.$store.state.map.hideWaterMeters },
  },
  watch: {
    rtuAutofocusTrigger () {
      if (this.$store.state.ux.settings.updated) {
        // If the current user has manually changed the map viewport (center / zoom), then do nothing;
        return false
      }
      this.centerMap()
    },
    selectedItemId () {
      this.$store.dispatch('rtu/setEditingItemId', null)
    }
  },
  mounted () {
    this.map = this.$refs.map.mapObject
    this.map.zoomControl.setPosition('bottomright')
    this.$bus.$on('map.center', this.centerMap)
  },
  beforeDestroy () {
    this.$bus.$off('map.center', this.centerMap)
  },
  methods: {
     setCenter (center) {
      this.$store.dispatch('map/setCenter', center)
      if (!this.$route.query.id) {
        this.$store.dispatch('ux/updateSettings', {map: {center}})
      }
    },
    setZoom (zoom) {
      this.$store.dispatch('map/setZoom', zoom)
      if (!this.$route.query.id) {
        this.$store.dispatch('ux/updateSettings', {map: {zoom}})
      }
    },
    centerMap (coords, coords2) {
      if (coords && coords2) {
        this.map.fitBounds([coords, coords2], {paddingTopLeft: [this.$route.query.id && this.$vuetify.breakpoint.width > 600 ? 600 : 0, 0]})
      } else if (coords) {
        if (this.$route.query.id && this.$vuetify.breakpoint.width > 600) {
          const containerPoint = this.map.latLngToContainerPoint(coords)
          containerPoint.x -= 300
          const point = this.map.containerPointToLatLng(containerPoint)
          this.map.panTo(point)
        } else {
          this.map.panTo(coords)
        }
      } else {
        const elements = this.items.length ? this.items : this.stationItems
        
        const bounds = elements.reduce((out, item) => {
            if (item.displaySettings) {
              if ((item.displaySettings.type === 'circle' || item.displaySettings.type === 'station') && item.displaySettings.center) {
                out.push(geometryUtil.destination(item.displaySettings.center, -45, (item.displaySettings.radius || 0) * 2))
                out.push(geometryUtil.destination(item.displaySettings.center, -45 + 180, (item.displaySettings.radius || 0) * 2))
              } else if (item.displaySettings.type === 'lateral' && item.displaySettings.path_coords) {
                out.push({lat: item.displaySettings.path_coords.start_lat, lng: item.displaySettings.path_coords.start_lng})
                out.push({lat: item.displaySettings.path_coords.end_lat, lng: item.displaySettings.path_coords.end_lng})
              }
            }
            return out
          }, [])
        if (bounds.length && this.map) {
          this.map.fitBounds(bounds, {paddingTopLeft: [this.$route.query.id && this.$vuetify.breakpoint.width > 600 ? 600 : 0, 0]})
        } 
      }
    },
    selectItem (item) {
      if (this.selectedItemId.toString() === item.id.toString()) { return false }
      this.$store.dispatch('rtu/setEditingItemId', null)
      if (item.displaySettings.type === 'circle' || item.displaySettings.type === 'lateral') {
        this.$router.push({name: 'main', query: {id: item.id}})
      } else if (item.displaySettings.type === 'station' || item.displaySettings.type === 'meteo') {
        this.$router.push({name: item.displaySettings.type, query: {id: item.id}})
      } else if (item.displaySettings.type === 'pump') {
        this.$router.push({name: 'scada', query: {id: item.id}})
      } else if (item.displaySettings.type === 'instart') {
        this.$router.push({name: 'instart', query: {id: item.id}})
      }
    },
    handleMouseClick () {
      if (this.editingItemId && this.selectedItemId) { return false }
      if (!this.selectedItemId && this.$route.name === 'main') { return false }
      this.$router.push({name: 'main', query: _.omit(this.$route.query, 'id')})
    }
  }
}
</script>
