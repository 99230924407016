var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showActual)?_c('div',{staticClass:"fc-map__page-body",staticStyle:{"overflow":"unset"}},[_c('div',{staticClass:"body-1"},[_c('v-card-text',[_c('div',{staticClass:"title mb-2"},[_vm._v(_vm._s(this.$t("monitoring.summaryActiveTemp")))]),_c('v-row',[_c('v-col',[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.sat'))+" "+_vm._s(_vm.$t('meteoSummary.above'))+" 0°C (SAT): "+_vm._s(_vm.sat0)+" ")])]}}],null,false,4112024456)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.satFormula'))+" 0°C.")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.sat'))+" "+_vm._s(_vm.$t('meteoSummary.above'))+" 5°C (SAT): "+_vm._s(_vm.sat5)+" ")])]}}],null,false,68517160)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.satFormula'))+" 5°C.")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.sat'))+" "+_vm._s(_vm.$t('meteoSummary.above'))+" 10°C (SAT): "+_vm._s(_vm.sat10)+" ")])]}}],null,false,1624101352)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.satFormula'))+" 10°C.")])])],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('meteoSummary.numberDays'))+": "+_vm._s(_vm.daysSum)+" ")])]),_c('v-col',[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.gdd'))+" 10°C (GDD1): "+_vm._s(_vm.gdd1)+" ")])]}}],null,false,891217439)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.gddFormula'))+" 10°C. "),_c('br'),_vm._v(" GDD = max((T"),_c('sub',[_vm._v("min")]),_vm._v(" + T"),_c('sub',[_vm._v("max")]),_vm._v(")/2 − T"),_c('sub',[_vm._v("base")]),_vm._v(", 0) ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.gdd'))+" 10°C (GDD2): "+_vm._s(_vm.gdd2)+" ")])]}}],null,false,1895587103)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.gddFormula'))+" 10°C."),_c('br'),_vm._v(" T"),_c('sub',[_vm._v("1")]),_vm._v(" = max(T"),_c('sub',[_vm._v("min")]),_vm._v(", T"),_c('sub',[_vm._v("base")]),_vm._v(")"),_c('br'),_vm._v(" T"),_c('sub',[_vm._v("2")]),_vm._v(" = max(T"),_c('sub',[_vm._v("max")]),_vm._v(", T"),_c('sub',[_vm._v("base")]),_vm._v(")"),_c('br'),_vm._v(" GDD = (T"),_c('sub',[_vm._v("1")]),_vm._v(" + T"),_c('sub',[_vm._v("2")]),_vm._v(")/2 − T"),_c('sub',[_vm._v("base")])])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.gdd'))+" 10°C/30°C (GDD3): "+_vm._s(_vm.gdd3)+" ")])]}}],null,false,3911591808)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.gddFormula'))+" 10°C."),_c('br'),_vm._v(" T"),_c('sub',[_vm._v("1")]),_vm._v(" = max(T"),_c('sub',[_vm._v("min")]),_vm._v(", T"),_c('sub',[_vm._v("base")]),_vm._v(")"),_c('br'),_vm._v(" T"),_c('sub',[_vm._v("2")]),_vm._v(" = min(max(T"),_c('sub',[_vm._v("max")]),_vm._v(", T"),_c('sub',[_vm._v("base")]),_vm._v("), T"),_c('sub',[_vm._v("baseMax")]),_vm._v(")"),_c('br'),_vm._v(" GDD = (T"),_c('sub',[_vm._v("1")]),_vm._v(" + T"),_c('sub',[_vm._v("2")]),_vm._v(")/2 − T"),_c('sub',[_vm._v("base")])])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t('meteoSummary.chu'))+": "+_vm._s(_vm.chu)+" ")])]}}],null,false,663994891)},[_c('span',[_vm._v(_vm._s(_vm.$t('meteoSummary.chuFormula')))]),_c('br'),_vm._v(" CHU = (1.8 * (T"),_c('sub',[_vm._v("min")]),_vm._v(" − 4.4) + 3.33 * (T"),_c('sub',[_vm._v("max")]),_vm._v(" − 10) − 0.084 * (T"),_c('sub',[_vm._v("max")]),_vm._v(" − 10)"),_c('sup',[_vm._v("2")]),_vm._v(") / 2 ")])],1)])],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }