export default {
  defaultLocale: 'ru',
  locales: [{
    key: 'ru', name: 'Русский', icon: 'ru'
  }, {
    key: 'en', name: 'English', icon: 'us'
  }, {
    key: 'es', name: 'Español', icon: 'es'
  }],
  cookieName: 'fc_locale',
  translations: {
    menu: {
      map: {
        en: 'Map',
        es: 'Mapa',
        ru: 'Карта'
      },
      irrigation: {
        en: 'Irrigation',
        es: 'Riego',
        ru: 'Орошение'
      },
      meteos: {
        en: 'Meteos',
        es: 'Meteo',
        ru: 'Станции'
      },
      reports: {
        en: 'Reports',
        es: 'Reportes',
        ru: 'Отчеты'
      },
      motohours: {
        en: 'Moto Hours',
        es: '',
        ru: 'Моточасы'
      },
      help: {
        en: 'Help',
        es: 'Ayuda',
        ru: 'Помощь'
      },
      contact: {
        en: 'Contact',
        es: 'Contacto',
        ru: 'Контакты'
      },
      login: {
        en: 'Login',
        es: 'Entrar',
        ru: 'Логин'
      },
      restorePassword: {
        en: 'Restore password',
        es: 'Restaurar contraseña',
        ru: 'Восстановление пароля'
      },
      settings: {
        en: 'Settings',
        es: 'Ajustes',
        ru: 'Настройки'
      },
      admin: {
        en: 'Admin Panel',
        es: 'Administración',
        ru: 'Администрирование'
      },
      notifications: {
        en: 'Notifications',
        es: 'Notificaciones',
        ru: 'Уведомления'
      }
    },
    mainMenu: {
      settings: {
        en: 'Settings',
        es: 'Configuraciones',
        ru: 'Режим работы'
      },
      map: {
        en: 'Position on the Map',
        es: 'Posición en el mapa',
        ru: 'Положение на карте'
      },
      guns: {
        en: 'End Guns',
        es: 'Cañón Final',
        ru: 'Концевые пушки'
      },
      hoseStop: {
        en: 'Hose Stop',
        es: 'Hidrantes',
        ru: 'Гидранты'
      },
      areaPlan: {
        en: 'Plans',
        es: 'Plan de riego',
        ru: 'План полива'
      },
      camera: {
        en: 'Camera',
        es: 'Cámara',
        ru: 'Камера'
      },
      ndvi: {
        en: 'NDVI',
        es: 'NDVI',
        ru: 'NDVI'
      },
      pivot: {
        en: 'Pivot',
        es: 'Pivot',
        ru: 'Пивот'
      },
      systemSettings: {
        en: 'System Settings',
        es: 'Configuración del sistema',
        ru: 'Системные настройки'
      },
      barrierSettings: {
        en: 'Barrier',
        es: 'Barrera',
        ru: 'Барьер'
      },
      reports: {
        en: 'Reports',
        es: 'Reportes',
        ru: 'Отчеты'
      },
      history: {
        en: 'History',
        es: '',
        ru: 'История'
      },
      log: {
        en: 'Log',
        es: '',
        ru: 'Лог'
      },
      watermark: {
        en: 'Watermark',
        es: 'Humedad del suelo',
        ru: 'Влажность почвы'
      }
    },
    form: {
      timezone: {
        en: 'Timezone',
        es: 'Huso horario',
        ru: 'Таймзона'
      },
      dateFormat: {
        en: 'Date format',
        es: 'Formato de fecha',
        ru: 'Формат даты'
      },
      direction: {
        frw: {
          en: 'Clockwise',
          es: 'Dextrorso',
          ru: 'По часовой стрелке'
        },
        rev: {
          en: 'Counter Clockwise',
          es: 'Sinistrórsum',
          ru: 'Против часовой стрелки'
        }
      },
      email: {
        en: 'Email',
        es: 'Email',
        ru: 'Адрес электронной почты'
      },
      password: {
        en: 'Password',
        es: 'Password',
        ru: 'Пароль'
      },
      speed: {
        en: 'Speed',
        es: 'Velocidad',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        es: 'Norma',
        ru: 'Норма'
      },
      delay: {
        en: 'Delay',
        es: 'Pausa',
        ru: 'Пауза'
      },
      delayStart: {
        en: 'Start Delay',
        es: 'Inicio retrasado',
        ru: 'Задержка запуска'
      },
      depthSuffix: {
        en: 'mm',
        es: 'mm',
        ru: 'мм'
      },
      time: {
        en: 'Time',
        es: 'Tiempo',
        ru: 'Время'
      },
      timeWithHoseStops: {
        en: 'Time',
        es: 'Tiempo',
        ru: 'Время 2'
      },
      timeSuffix: {
        en: 'h',
        es: 'h',
        ru: 'ч'
      },
      timeSuffixHour: {
        en: 'h',
        es: 'h',
        ru: 'ч'
      },
      timeSuffixMinute: {
        en: 'min',
        es: 'min',
        ru: 'мин'
      },
      timeSuffixSec: {
        en: 'sec',
        es: 'sec',
        ru: 'сек'
      },
      pressure: {
        en: 'Pressure',
        es: 'Presión',
        ru: 'Давление'
      },
      pressureSuffix: {
        en: 'Bar',
        es: 'Bar',
        ru: 'Бар'
      },
      uart: {
        en: 'UART',
        es: 'UART',
        ru: 'UART'
      },
      gnss: {
        en: 'GNSS',
        es: 'GNSS',
        ru: 'GNSS'
      },
      startWoGnss: {
        en: 'W/O GNSS',
        es: 'W/O GNSS',
        ru: 'Без GNSS'
      },
      sensor: {
        en: 'Sensor',
        es: 'Sensor',
        ru: 'Sensor'
      },
      bar: {
        en: 'Bar',
        es: 'Bar',
        ru: 'Бар'
      },
      flow: {
        en: 'Flow',
        es: 'Fluir',
        ru: 'Поток'
      },
      flowSuffix: {
        // en: 'l/s',
        // ru: 'л/c',
        en: 'm³/h',
        es: 'm³/h',
        ru: 'м³/ч'
      },
      amperage: {
        en: 'Ampere',
        es: 'Ampere',
        ru: 'Ток'
      },
      voltage: {
        en: 'Voltage',
        es: 'Voltaje',
        ru: 'Напряжение'
      },
      frequency: {
        en: 'Frequency',
        es: 'Frequency',
        ru: 'Частота'
      },
      power: {
        en: 'Power',
        es: 'Power',
        ru: 'Мощность'
      },
      voltageSuffix: {
        en: 'Volt',
        es: 'Voltio',
        ru: 'Вольт'
      },
      voltageShortSuffix: {
        en: 'V',
        es: 'V',
        ru: 'V'
      },
      frequencySuffix: {
        en: 'Hz',
        es: 'Hz',
        ru: 'Гц'
      },
      powerSuffix: {
        en: 'kW',
        es: 'kW',
        ru: 'кВт'
      },
      amperageSuffix: {
        en: 'Ampere',
        es: 'Ampere',
        ru: 'Ампер'
      },
      amperageShortSuffix: {
        en: 'A',
        es: 'A',
        ru: 'A'
      },
      temperature: {
        en: 'Temperature',
        es: 'Temperatura',
        ru: 'Температура'
      },
      temperatureSuffix: {
        en: '°C',
        es: '°C',
        ru: '°C'
      },
      hertzSuffix: {
        en: 'Hz',
        es: 'Hz',
        ru: 'Гц'
      },
      serviceStop: {
        en: 'Service Stop',
        es: 'Parada de servicio',
        ru: 'Сервисная остановка'
      },
      parking: {
        en: 'Parking',
        es: 'Aparcamiento',
        ru: 'Парковка'
      },
      serviceStopRepeat: {
        en: 'Repeat',
        es: 'Repetir',
        ru: 'Повтор'
      },
      toggle: {
        en: 'On',
        es: 'En',
        ru: 'Вкл'
      },
      comment: {
        en: 'Comment',
        es: 'Comentario',
        ru: 'Комментарий'
      },
      lat: {
        en: 'Latitude',
        es: 'Latitude',
        ru: 'Широта'
      },
      lng: {
        en: 'Longitude',
        es: 'Longitude',
        ru: 'Долгота'
      },
      startLat: {
        en: 'Start Latitude',
        es: 'Latitud inicial',
        ru: 'Начальная широта'
      },
      startLng: {
        en: 'Start Longitude',
        es: 'Longitud inicial',
        ru: 'Начальная долгота'
      },
      endLat: {
        en: 'End Latitude',
        es: 'Latitud final',
        ru: 'Конечная широта'
      },
      endLng: {
        en: 'End Longitude',
        es: 'Longitud final',
        ru: 'Конечная долгота'
      },
      lateralHeight: {
        en: 'Device Height',
        es: 'Longitud del dispositivo',
        ru: 'Длина устройства'
      },
      lateralLength: {
        en: 'Field Length',
        es: 'Longitud del campo',
        ru: 'Длина поля'
      },
      startPosition: {
        en: 'Start Position',
        es: 'Posición inicial',
        ru: 'Начальное положение'
      },
      endPosition: {
        en: 'Run Length',
        es: 'Longitud de la carretera',
        ru: 'Длина гона'
        // en: 'End Position',
        // ru: 'Конечное положение'
      },
      radius: {
        en: 'Radius',
        es: 'Radio',
        ru: 'Радиус'
      },
      radiusSuffix: {
        en: 'm',
        es: 'm',
        ru: 'м'
      },
      hoseStopSuffix: {
        en: 'm',
        es: 'm',
        ru: 'м'
      },
      watermarkSuffix: {
        en: '%',
        es: '%',
        ru: '%'
      },
      gunIndex: {
        en: 'Area',
        es: 'Región',
        ru: 'Область'
      },
      gunStart: {
        en: 'Start Angle',
        es: 'Angulo inicial',
        ru: 'Начальный угол'
      },
      gunEnd: {
        en: 'End Angle',
        es: 'Angulo final',
        ru: 'Конечный угол'
      },
      gunStartLateral: {
        en: 'Start Position',
        es: 'Posición inicial',
        ru: 'Начальное положение'
      },
      gunEndLateral: {
        en: 'End Position',
        es: 'Posición final',
        ru: 'Конечное положение'
      },
      startAngle: {
        en: 'Start Angle',
        es: 'Angulo inicial',
        ru: 'Начальный угол'
      },
      endAngle: {
        en: 'End Angle',
        es: 'Angulo final',
        ru: 'Конечный угол'
      },
      offsetAngle: {
        en: 'Offset Angle',
        es: 'Ángulo de compensación',
        ru: 'Угол смещения'
      },
      language: {
        en: 'Language',
        es: 'Idiomas',
        ru: 'Язык'
      },
      barrierFrom: {
        en: 'Start',
        es: 'Comenzar',
        ru: 'Начало',
      },
      barrierTo: {
        en: 'End',
        es: 'Final',
        ru: 'Конец',
      },
      barrierHoldFor: {
        en: 'Hold For',
        es: 'Demora',
        ru: 'Задержка',
      },
      motohour: {
        en: 'Moto Hour',
        es: '',
        ru: 'Моточасы',
      },
      circles: {
        en: 'Circles',
        es: 'Círculos',
        ru: 'Круги'
      },
      user: {
        name: {
          en: 'User Name',
          es: '',
          ru: 'Имя пользователя'
        },
        email: {
          en: 'Email',
          es: 'Email',
          ru: 'Адрес электронной почты'
        },
        password: {
          en: 'Password',
          es: 'Contraseña',
          ru: 'Пароль'
        },
        role: {
          en: 'Role',
          es: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          es: 'Organización',
          ru: 'Организация'
        },
        allDevices: {
          en: 'All Devices Accessible',
          es: '',
          ru: 'Все устройства доступны'
        },
        allDevicesShort: {
          en: 'All',
          es: 'Todo',
          ru: 'Все'
        },
        devices: {
          en: 'Accessible Devices',
          es: 'Dispositivos accesibles',
          ru: 'Доступные устройства'
        }
      },
      pivot: {
        uuid: {
          en: 'UUID',
          es: 'UUID',
          ru: 'UUID'
        },
        ip: {
          en: 'Socket ID',
          es: 'Socket ID',
          ru: 'ID сокета'
        },
        name: {
          en: 'Name',
          es: 'Nombre',
          ru: 'Название'
        },
        manufacturer: {
          en: 'Manufacturer',
          es: 'Fabricante',
          ru: 'Производитель'
        }
      },
      placement: {
        en: 'Placement',
        es: 'Colocación',
        ru: 'Размещение'
      },
      device: {
        uid: {
          en: 'UID',
          es: 'UID',
          ru: 'UID'
        },
        mac: {
          en: 'Id',
          es: 'Id',
          ru: 'MAC/IMEI/ID'
        }
      },
      organization: {
        name: {
          en: 'Organization Name',
          es: 'Nombre de la organización',
          ru: 'Название организации'
        },
        devices: {
          en: 'Accessible Devices',
          es: 'Dispositivos accesibles',
          ru: 'Доступные устройства'
        },
        proxy: {
          ip: {
            en: 'IP',
            es: 'IP',
            ru: 'IP'
          },
          port: {
            en: 'Port',
            es: 'Port',
            ru: 'Порт'
          }
        }
      },
      camera: {
        ip: {
          en: 'IP',
          es: 'IP',
          ru: 'IP'
        },
        port: {
          en: 'Port',
          es: 'Port',
          ru: 'Порт'
        },
        login: {
          en: 'Login',
          es: 'Entrar',
          ru: 'Логин'
        },
        password: {
          en: 'Password',
          es: 'Contraseña',
          ru: 'Пароль'
        },
        status: {
          en: 'Status',
          es: 'Status',
          ru: 'Статус'
        },
        cameraNum: {
          en: 'Camera No',
          es: 'Cámara No',
          ru: 'Номер камеры'
        }
      },
      settings: {
        timeOffset: {
          en: 'Time Offset',
          es: 'Huso horario',
          ru: 'Таймзона'
        },
        timeOffsetHint: {
          en: 'In hours, relatively to UTC e.g. +1, +2, etc.',
          es: 'En horas, en relación con UTC',
          ru: 'В часах относительно UTC, например +1, +2, итд.'
        }
      },
      hoseStopPosition: {
        en: 'Position',
        es: 'Posición',
        ru: 'Позиция'
      },
      hoseStopAlertDistance: {
        en: 'Alert Distance',
        es: 'Advertencia',
        ru: 'Предупреждение'
      },
      hoseStopAlign: {
        en: 'Align',
        es: 'Alineación',
        ru: 'Выравнивание'
      },
      hoseStopVisibility: {
        en: 'Show',
        es: 'Mostrar',
        ru: 'Отображать'
      },
      areaPlanStart: {
        en: 'Start',
        es: 'Comenzar',
        ru: 'Начало'
      },
      areaPlanEnd: {
        en: 'End',
        es: 'Final',
        ru: 'Конец'
      },
      areaPlanIrrigation: {
        en: 'Irrigation',
        es: 'Riego',
        ru: 'Полив'
      },
      watermarkDate: {
        en: 'Date',
        es: 'Fecha',
        ru: 'Дата'
      },
      watermarkDepth: {
        en: 'Depth',
        es: 'Profundidad',
        ru: 'Глубина'
      },
      watermarkValue: {
        en: 'Value',
        es: 'Significado',
        ru: 'Значение'
      },
    },
    actions: {
      requestPivot: {
        en: 'Request Pivot Settings',
        es: 'Solicitud',
        ru: 'Запросить'
      },
      requestLog: {
        en: 'Request Pivot Log',
        es: 'Solicitud',
        ru: 'Запросить лог устройства'
      },
      requestPump: {
        en: 'Request Pump Settings',
        es: 'Configuración de solicitud',
        ru: 'Запросить настройки насоса'
      },
      firmware: {
        en: 'Firmware',
        es: 'Firmware',
        ru: 'Прошить'
      },
      startEditEndGuns: {
        en: 'Edit End Guns',
        es: 'Editar Cañón Final',
        ru: 'Редактировать концевые пушки'
      },
      edit: {
        en: 'Edit',
        es: 'Editar',
        ru: 'Редактировать'
      },
      login: {
        en: 'Log In',
        es: 'Entrar',
        ru: 'Войти'
      },
      logout: {
        en: 'Log Out',
        es: 'Salir',
        ru: 'Выйти'
      },
      settings: {
        en: 'Settings',
        es: 'Ajustes',
        ru: 'Настройки'
      },
      notifications: {
        en: 'Notifications',
        es: 'Notificaciones',
        ru: 'Уведомления'
      },
      admin: {
        en: 'Admin',
        es: 'Admin',
        ru: 'Админ'
      },
      restorePassword: {
        en: 'Restore Password',
        es: 'Restaurar contraseña',
        ru: 'Восстановить пароль'
      },
      centerMap: {
        en: 'Center Map',
        es: 'Centrarse en los objetos',
        ru: 'Центрировать на объектах'
      },
      refresh: {
        en: 'Refresh',
        es: 'Actualice',
        ru: 'Обновить'
      },
      apply: {
        en: 'Apply',
        es: 'Solicitar',
        ru: 'Применить'
      },
      add: {
        en: 'Add',
        es: 'Añade',
        ru: 'Добавить'
      },
      create: {
        en: 'Create',
        es: 'Cree',
        ru: 'Создать'
      },
      save: {
        en: 'Save',
        es: 'Guardar',
        ru: 'Сохранить'
      },
      cancel: {
        en: 'Cancel',
        es: 'Cancelación',
        ru: 'Отмена'
      },
      editOnTheMap: {
        en: 'Edit Position',
        es: 'Cambiar posición en el mapa',
        ru: 'Изменить положение на карте'
      },
      request: {
        en: 'Request',
        es: 'Petición',
        ru: 'Опросить'
      },
      gotoLogin: {
        en: 'Login',
        es: 'Login',
        ru: 'Войти'
      },
      gotoRestorePassword: {
        en: 'Restore password',
        es: 'Restaurar contraseña',
        ru: 'Восстановление пароля'
      },
      close: {
        en: 'Close',
        es: 'Cerrar',
        ru: 'Закрыть'
      },
      userNew: {
        en: 'Create New User',
        es: '',
        ru: 'Создать нового пользователя'
      },
      organizationNew: {
        en: 'Create New Organization',
        es: '',
        ru: 'Создать новую организацию'
      },
      pivotNew: {
        en: 'Create New Pivot',
        es: '',
        ru: 'Создать новую машину'
      },
      delete: {
        en: 'Delete',
        es: 'Borrar',
        ru: 'Удалить'
      },
      addCamera: {
        en: 'Add',
        es: 'Agregar',
        ru: 'Добавить'
      },
      editCamera: {
        en: 'Edit',
        es: 'Editar',
        ru: 'Редактировать'
      },
      requestReport: {
        en: 'Load data',
        es: '',
        ru: 'Загрузить отчет'
      },
      find: {
        en: 'Find',
        es: 'Encontrar',
        ru: 'Найти'
      },
      locateMe: {
        en: 'Locate Me',
        es: 'Localizame',
        ru: 'Найти меня'
      },
      editWatermarkSensor: {
        en: 'Watermark Sensor',
        es: 'Sensor de marca de agua',
        ru: 'Настройки датчиков'
      }
    },
    irrigationColumns: {
      equipment: {
        en: 'Name',
        es: 'Nombre',
        ru: 'Имя'
      },
      status: {
        en: 'Status',
        es: 'Estado',
        ru: 'Статус'
      },
      speed: {
        en: 'Speed',
        es: 'Velocidad',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        es: 'Norma',
        ru: 'Норма'
      },
      time: {
        en: 'Time',
        es: 'Tiempo',
        ru: 'Время'
      },
      serviceStop: {
        en: 'Serv.Stop',
        es: 'Serv.Stop',
        ru: 'Серв.Ост.'
      },
      management: {
        en: 'Management',
        es: 'Manejo',
        ru: 'Управление'
      },
      camera: {
        en: 'Camera',
        es: 'Cámara',
        ru: 'Камера'
      },
      connection: {
        en: 'Connection',
        es: 'Conexión',
        ru: 'Связь'
      },
      last_update: {
        en: 'Updated',
        es: 'Actualizado',
        ru: 'Обнов.'
      }
    },
    meteosColumns: {
      id: {
        en: 'Id',
        es: 'Id',
        ru: 'Id'
      },
      name: {
        en: 'Name',
        es: 'Nombre',
        ru: 'Имя'
      },
      device_name: {
        en: 'Station name',
        es: 'Nombre de la estación',
        ru: 'Имя устройства'
      },
      type: {
        en: 'Type',
        es: 'Tipo',
        ru: 'Тип'
      },
      connection: {
        en: 'Connection',
        es: 'Conexión',
        ru: 'Связь'
      },
      moisture: {
        en: 'Moisture',
        es: 'Humedad',
        ru: 'Влажность'
      },
      temperature: {
        en: 'Temp',
        es: 'Temp',
        ru: 'Темп'
      },
      airrh: {
        en: 'Air Rh',
        es: 'Aire Rh',
        ru: 'Влажн'
      },
      dewpoint: {
        en: 'Dew Point',
        es: 'P.rocío',
        ru: 'Т.росы'
      },
      windspeed: {
        en: 'Wind Speed',
        es: 'Viento',
        ru: 'Ветер'
      },
      windspeedmax: {
        en: 'Wind Gust',
        es: 'Ráfagas',
        ru: 'Порывы'
      },
      windspeedavg: {
        en: 'Wind Average',
        es: 'Media',
        ru: 'Средняя'
      },
      winddirection: {
        en: 'Wind Dir',
        es: 'Direc',
        ru: 'Направл'
      },
      winddirection2: {
        en: 'Wind Direction',
        es: 'Dirección',
        ru: 'Направление'
      },
      shortwaveRadiation: {
        en: 'Shortwave Radiation',
        es: 'Radiación solar',
        ru: 'Солнечная радиация'
      },
      evapotranspiration: {
        en: 'Evapotranspiration',
        es: 'Evapotranspiración',
        ru: 'Эвапотранспирация'
      },
      sunrise: {
        en: 'Sunrise',
        es: 'Amanecer',
        ru: 'Восход'
      },
      sunset: {
        en: 'Sunset',
        es: 'Atardecer',
        ru: 'Закат'
      },
      dayLength: {
        en: 'Day length',
        es: 'Duración del día',
        ru: 'Длительность дня'
      },
      UVIndex: {
        en: 'UV-index',
        es: 'Índice UV',
        ru: 'УФ-индекс'
      },
      rain: {
        en: 'Rain',
        es: 'Lluvia',
        ru: 'Дождь'
      },
      probability: {
        en: 'Probability',
        es: 'Probabilidad',
        ru: 'Вероятность'
      },
      probabilityPrecipitation: {
        en: 'Probability Precipitation',
        es: 'Posibilidad de precipitación',
        ru: 'Вероятность осадков'
      },
      rain1h: {
        en: 'Rain 1 Hour',
        es: 'Lluvia 1 hora',
        ru: 'Дождь,1ч'
      },
      date: {
        en: 'Date',
        es: 'Fecha',
        ru: 'Дата'
      },
      in1H: {
        en: 'In 1 hour',
        es: 'En 1 hora',
        ru: 'За час'
      },
      in12H: {
        en: 'In 12 hours',
        es: 'En 12 horas',
        ru: 'За 12 часов'
      },
      feelLike: {
        en: 'Feel like',
        es: 'Se siente como',
        ru: 'Ощущается как'
      }
    },
    instartColumns: {
      name: {
        en: 'Name',
        es: 'Nombre',
        ru: 'Название',
      },
      amperage: {
        en: 'Amp',
        es: 'Amp',
        ru: 'Амп',
      },
      voltage: {
        en: 'Volt',
        es: 'Volt',
        ru: 'Вольт',
      },
      frequency: {
        en: 'Freq',
        es: 'Freq',
        ru: 'Частота',
      },
      power: {
        en: 'Power',
        es: 'Power',
        ru: 'Мощность',
      },
      state: {
        en: 'State',
        es: 'State',
        ru: 'Состояние',
      },
      monitoring: {
        en: 'Monitoring',
        es: 'Monitoring',
        ru: 'Мониторинг',
      },
    },
    instart: {
      title: {
        en: 'Показатели преобразователя частоты',
        es: 'Показатели преобразователя частоты',
        ru: 'Показатели преобразователя частоты',
      },
      stop: {
        en: 'Stop',
        es: 'Stop',
        ru: 'Стоп',
      },
      start: {
        en: 'Start',
        es: 'Start',
        ru: 'Запуск',
      },
      turnedOn: {
        en: 'TURNED ON',
        es: 'ENCENDIDO',
        ru: 'РАБОТАЕТ',
      },
      turnedOff: {
        en: 'TURNED OFF',
        es: 'APAGADO',
        ru: 'ОСТАНОВЛЕН',
      },
      status: {
        stop: {
          en: 'Stoped',
          es: 'Stoped',
          ru: 'Остановлен',
        },
        start: {
          en: 'In work',
          es: 'In work',
          ru: 'Работает',
        },
        frw: {
          en: 'In work',
          es: 'In work',
          ru: 'Работает',
        },
      }
    },
    status: {
      no_connection: {
        en: 'No Connection',
        es: 'Sin conexión',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'Alignment error',
        es: 'Error de alineación',
        ru: 'Ошибка выравнивания'
      },
      lic_error: {
        en: 'License error',
        es: 'Error de licencia',
        ru: 'Ошибка лицензии Valley'
      },
      prsr_low: {
        en: 'Low pressure',
        es: 'Baja presión',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'Restart delay',
        es: 'Retraso de reinicio',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'Pressure waiting delay',
        es: 'Retardo de espera por presión',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'Pressure recovery',
        es: 'Recuperación de presión',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'Minimum allowable pressure',
        es: 'Presión mínima admisible',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        es: 'Over Water',
        ru: 'Over Water'
      },
      prepare: {
        en: 'Prepare',
        es: 'Preparación',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        es: 'Error de GPS',
        ru: 'Ошибка GPS'
      },
      sft_error: {
        en: 'Safety Error',
        es: 'Error de seguridad',
        ru: 'Цепь безопасности'
      },
      enrg_low: {
        en: 'Low Energy Error',
        es: 'Error de baja energía',
        ru: 'Ошибка низкого напряжения'
      },
      voltage_error: {
        en: 'Voltage Error',
        es: 'Error de voltaje',
        ru: 'Отсутствие напряжения'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        es: 'Error del sensor de posición',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        es: 'Error de temperatura',
        ru: 'Ошибка температуры'
      },
      service_stop: {
        en: 'Service Stop',
        es: 'Parada de servicio',
        ru: 'Сервисная остановка'
      },
      barrier_stop: {
        en: 'Barrier Stop',
        es: 'Parada de barrera',
        ru: 'Автоматическая остановка у барьера'
      },
      acc_frw: {
        en: 'Forward with ACC',
        es: 'Adelante con Fert',
        ru: 'Движение вперед с удобрениями'
      },
      acc_rev: {
        en: 'Reverse with ACC',
        es: 'Inverso con Fert',
        ru: 'Движение назад с удобрениями'
      },
      irrigation_frw: {
        en: 'Forward with irrigation',
        es: 'Adelante con riego',
        ru: 'Движение вперед с водой'
      },
      irrigation_rev: {
        en: 'Reverse with irrigation',
        es: 'Inverso con riego',
        ru: 'Движение назад с водой'
      },
      frw: {
        en: 'Forward',
        es: 'Adelante',
        ru: 'Движение вперед'
      },
      rev: {
        en: 'Reverse',
        es: 'Inverso',
        ru: 'Движение назад'
      },
      stop: {
        en: 'Stop',
        es: 'Stop',
        ru: 'Движение прекращено'
      },
      move: {
        en: 'Move',
        es: 'Mover',
        ru: 'Движ.'
      },
      online: {
        en: 'Online',
        es: 'En línea',
        ru: 'Онлайн'
      },
      offline: {
        en: 'Offline',
        es: 'Desconectado',
        ru: 'Оффлайн'
      },
      finish: {
        en: 'Stop',
        es: 'Detener',
        ru: 'Движение прекращено'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        es: 'Barrera blanda',
        ru: 'Мягкий барьер'
      },
      relay_online: {
        en: 'Relay Online',
        es: 'Relé en línea',
        ru: 'Реле Онлайн'
      },
      relay_offline: {
        en: 'Relay Offline',
        es: 'Relé desconectado',
        ru: 'Реле Оффлайн'
      },
      engine_off: {
        en: 'Engine Off',
        es: 'Motor apagado',
        ru: 'Двигатель выключен'
      }
    },
    statusShort: {
      no_connection: {
        en: 'No Connection',
        es: 'Sin conexión',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'Alignment error',
        es: 'Error de alineación',
        ru: 'Ошибка выравн.'
      },
      lic_error: {
        en: 'License error',
        es: 'Error de licencia',
        ru: 'Ошибка лицензии Valley'
      },
      enrg_low: {
        en: 'Low Energy Error',
        es: 'Error de baja energía',
        ru: 'Низкое напряжение'
      },
      prsr_low: {
        en: 'Low pressure',
        es: 'Baja presión',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'Restart delay',
        es: 'Retraso de reinicio',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'Pressure waiting delay',
        es: 'Retardo de espera por presión',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'Pressure recovery',
        es: 'Recuperación de presión',
        ru: 'Восст-е давл.'
      },
      prsr_min: {
        en: 'Min.all.pres',
        es: 'Presión mín.adm',
        ru: 'Мин.доп.давл.'
      },
      over_water: {
        en: 'Over Water',
        es: 'Over Water',
        ru: 'Over Water'
      },
      end_tower_sensor: {
        en: 'End Tower Sensor',
        es: 'End Tower Sensor',
        ru: 'End Tower Sensor'
      },
      wrong_dir: {
        en: 'Wrong Direction',
        es: 'Dirección equivocada',
        ru: 'Ошибка направления'
      },
      pl_con_err: {
        en: 'PL Comm Lost',
        es: '',
        ru: 'Нет соединения с PL'
      },
      ppt_pro_err: {
        en: 'PPT Pro Error',
        es: '',
        ru: 'Ошибка PPT Pro'
      },
      prepare: {
        en: 'Prepare',
        es: 'Preparación',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        es: 'Error de GPS',
        ru: 'Ошибка GPS'
      },
      voltage_error: {
        en: 'Voltage Error',
        es: 'Error de voltaje',
        ru: 'Отсутствие напряжения'
      },
      sft_error: {
        en: 'Safety Error',
        es: 'Error de seguridad',
        ru: 'Цепь безопасности'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        es: 'Error del sensor de posición',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        es: 'Error de temperatura',
        ru: 'Ошибка температуры'
      },
      service_stop: {
        en: 'Srv.Stop',
        es: 'Srv.Stop',
        ru: 'Серв.Ост.'
      },
      barrier_stop: {
        en: 'Barrier.Stop',
        es: 'Barrera',
        ru: 'Барьер'
      },
      acc_frw: {
        en: 'Frw with ACC',
        es: 'Ade con Fert',
        ru: 'Дв.вперед с удобр.'
      },
      acc_rev: {
        en: 'Rev with ACC',
        es: 'Inv con Fert',
        ru: 'Дв.назад с удобр.'
      },
      irrigation_frw: {
        en: 'Frw with irrigation',
        es: 'Ade con riego',
        ru: 'Дв.вперед с водой'
      },
      irrigation_rev: {
        en: 'Rev with irrigation',
        es: 'Inv con riego',
        ru: 'Дв.назад с водой'
      },
      frw: {
        en: 'Frw',
        es: 'Ade',
        ru: 'Дв.вперед'
      },
      rev: {
        en: 'Rev',
        es: 'Inv',
        ru: 'Дв.назад'
      },
      stop: {
        en: 'Stop',
        es: 'Stop',
        ru: 'Останов.'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        es: 'Barrera blanda',
        ru: 'Мягкий барьер'
      }
    },
    workPlan: {
      manual: {
        en: 'Manual management',
        es: 'Control manual',
        ru: 'Ручное управление'
      },
      auto: {
        en: 'Auto',
        es: 'Control auto',
        ru: 'Авто'
      }
    },
    controls: {
      move: {
        start: {
          en: 'Start',
          es: 'Start',
          ru: 'Запустить'
        },
        off: {
          en: 'Stop',
          es: 'Stop',
          ru: 'Остановить'
        },
        frw: {
          en: 'Forward',
          es: 'Adelante',
          ru: 'Вперед'
        },
        rev: {
          en: 'Reverse',
          es: 'Inverso',
          ru: 'Назад'
        },
        finish: {
          en: 'Stop',
          es: 'Stop',
          ru: 'Остановка'
        }
      },
      irrigation: {
        off: {
          en: 'Irrigaion Off',
          es: 'Riego apagado',
          ru: 'Выключить воду'
        },
        on: {
          en: 'Irrigation On',
          es: 'Riego encendido',
          ru: 'Включить воду'
        }
      },
      acc: {
        off: {
          en: 'ACC Off',
          es: 'Fertilizantes apagado',
          ru: 'Выключить ACC'
        },
        on: {
          en: 'ACC On',
          es: 'Fertilizantes encendido',
          ru: 'Включить ACC'
        }
      },
      accMixer: {
        off: {
          en: 'ACC Mixer Off',
          es: 'Fertilizantes mezclador apagado',
          ru: 'Выкл ACC Микс'
        },
        on: {
          en: 'ACC Mixer On',
          es: 'Fertilizantes mezclador encendido',
          ru: 'Вкл ACC Микс'
        }
      },
      autoRev: {
        off: {
          en: 'Autoreverse Off',
          es: 'Autoreversa apagado',
          ru: 'Выключить Авторазворот'
        },
        on: {
          en: 'Autoreverse On',
          es: 'Autoreversa encendido',
          ru: 'Включить Авторазворот'
        }
      },
      autoStart: {
        off: {
          en: 'Autorestart Off',
          es: 'Inicio automático apagado',
          ru: 'Выключить Автоперезапуск'
        },
        on: {
          en: 'Autorestart On',
          es: 'Inicio automático encendido',
          ru: 'Включить Автоперезапуск'
        }
      }
    },
    errors: {
      incorrectToken: {
        en: 'Incorrect authentication token',
        es: 'Token de autenticación incorrecto',
        ru: 'Неверный токен авторизации'
      },
      formValidation: {
        en: 'Some form fields are not filled correctly',
        es: '',
        ru: 'Некоторые поля формы заполнены неверно'
      },
      incorrectEmailOrPassword: {
        en: 'Incorrect Email or Password',
        es: 'Incorrect Email or Password',
        ru: 'Неправильный email или пароль'
      },
      fields: {
        noEmail: {
          en: 'Email is not specified',
          es: 'Email is not specified',
          ru: 'Email не указан'
        },
        noPassword: {
          en: 'Password is not specified',
          es: 'Password is not specified',
          ru: 'Пароль не указан'
        }
      },
      updateSnapshot: {
        en: 'Failed receiving the new snapshot',
        es: 'Failed receiving the new snapshot',
        ru: 'Ошибка получения нового снимка камеры'
      }
    },
    success: {
      pivotRequested: {
        en: 'Pivot Requested',
        es: 'Se solicitan ajustes de pivot',
        ru: 'Настройки пивота запрошены'
      },
      pumpRequested: {
        en: 'Pump Requested',
        es: 'Configuración de pompa solicitada',
        ru: 'Настройки насоса запрошены'
      },
      barrierRequested: {
        en: 'Soft Barrier Requested',
        es: 'Se solicitan configuraciones de barrera',
        ru: 'Настройки барьера запрошены'
      },
      logRequested: {
        en: 'Log Requested',
        es: '',
        ru: 'Лог запрошен'
      },
      noSystemSettings: {
        en: 'No System Settings',
        es: '',
        ru: 'Системные настройки не найдены'
      },
      settingsUpdated: {
        en: 'Settings Updated',
        es: 'Configuración actualizada',
        ru: 'Настройки обновлены'
      },
      barrierUpdated: {
        en: 'Soft Barrier Updated',
        es: 'Datos de barrera actualizados',
        ru: 'Данные барьера обновлены'
      },
      cameraSettingsUpdated: {
        en: 'Camera Settings Updated',
        es: 'Se han actualizado los ajustes de la cámara',
        ru: 'Настройки камеры обновлены'
      },
      userCreated: {
        en: 'New User Created',
        es: '',
        ru: 'Создан новый пользователь'
      },
      userUpdated: {
        en: 'User Data Updated',
        es: '',
        ru: 'Данные пользователя обновлены'
      },
      userDeleted: {
        en: 'User Deleted',
        es: '',
        ru: 'Пользователь удален'
      },
      organizationCreated: {
        en: 'New Organization Created',
        es: '',
        ru: 'Создана новая организация'
      },
      organizationUpdated: {
        en: 'Organization Data Updated',
        es: '',
        ru: 'Данные организации обновлены'
      },
      organizationDeleted: {
        en: 'Organization Deleted',
        es: '',
        ru: 'Организация удалена'
      },
      loggedIn: {
        en: 'You\'ve logged in',
        es: '',
        ru: 'Вы вошли в систему'
      },
      loggedOut: {
        en: 'You\'ve logged out',
        es: '',
        ru: 'Вы вышли из системы'
      },
      cmdSent: {
        en: 'The command has been sent to the device',
        es: 'El comando ha sido enviado al dispositivo',
        ru: 'Команда отправлена на устройство'
      },
      coordsUpdated: {
        en: 'Device position updated',
        es: 'Posición del dispositivo actualizada',
        ru: 'Положение на карте сохранено'
      },
      requestSent: {
        en: 'Request sent',
        es: 'Datos actualizados',
        ru: 'Данные обновлены'
      },
      noEndGuns: {
        en: 'End guns are not set for this device',
        es: 'Cañón final no están instaladas para este dispositivo.',
        ru: 'Концевые пушки для данного устройства не установлены'
      },
      endGunsUpdated: {
        en: 'End guns updated',
        es: 'Cañón final actualizados',
        ru: 'Данные по концевым пушкам проставлены'
      },
      hoseStopsUpdated: {
        en: 'Hose stops updated',
        es: 'Los ajustes están establecidos',
        ru: 'Настройки заданы'
      },
      areaPlansUpdated: {
        en: 'Area Plans updated',
        es: 'Los ajustes están establecidos',
        ru: 'Настройки заданы'
      },
      watermarkUpdated: {
        en: 'Watermark updated',
        es: 'Datos del sensor de humedad del suelo actualizados',
        ru: 'Данные датчика влажности почвы обновлены'
      },
      watermarkSettingsUpdated: {
        en: 'Watermark settings updated',
        es: 'Se actualizaron los ajustes del sensor de humedad del suelo',
        ru: 'Настройки датчика влажности почвы обновлены'
      },
      noWatermark: {
        en: 'Watermark are not set for this device',
        es: 'Lecturas del sensor de humedad del suelo no configuradas',
        ru: 'Показатели датчика влажности почвы не заданы'
      },
      noCamera: {
        en: 'Camera is not set for this device',
        es: 'No hay ninguna cámara instalada en este dispositivo.',
        ru: 'Камера для данного устройства не установлена'
      },
      noNdvi: {
        en: 'NDVI is not set for this area',
        es: '',
        ru: 'Снимок NDVI для данной местности не найден'
      },
      noReport: {
        en: 'Data not found',
        es: 'Datos no encontrados',
        ru: 'Данные не найдены'
      },
      confirmStartDisel: {
        en: 'Confirm diesel start',
        es: 'Confirmar arranque del motor',
        ru: 'Подтвердите запуск двигателя'
      },
      confirmStopDisel: {
        en: 'Confirm diesel stop',
        es: 'Confirmar apagado del motor',
        ru: 'Подтвердите выключение двигателя'
      },
      confirmStartFreq: {
        en: 'Confirm frequency converter start',
        es: 'Confirmar arranque del convertidor de frecuencia',
        ru: 'Подтвердите запуск преобразователя'
      },
      confirmStopFreq: {
        en: 'Confirm frequency converter stop',
        es: 'Confirmar apagado del convertidor de frecuencia',
        ru: 'Подтвердите остановку преобразователя'
      },
      confirmFirmware: {
        en: 'Confirm Firmware Initial',
        es: '',
        ru: 'Подтвердите начало перепрошивки'
      },
      firmwareStart: {
        en: 'Firmware Start',
        es: '',
        ru: 'Процесс перепрошивки запущен'
      },
      noMeteoData: {
        en: 'Meteo data not found',
        es: 'Datos no encontrados',
        ru: 'Данные не найдены'
      }
    },
    endGun: {
      title: {
        en: 'End Gun',
        es: 'Cañón Final',
        ru: 'Концевая пушка'
      },
      suffix: {
        en: 'm',
        es: 'm',
        ru: 'м'
      },
      noAreas: {
        en: 'End gun areas are not defined',
        es: 'No hay áreas especificadas',
        ru: 'Области не заданы'
      }
    },
    hoseStop: {
      title: {
        en: 'Hose Stop',
        es: 'Hidrantes',
        ru: 'Гидранты'
      },
      suffix: {
        en: 'm',
        es: 'm',
        ru: 'м'
      },
    },
    watermark: {
      title: {
        en: 'Watermark. Manual input',
        es: 'Humedad del suelo',
        ru: 'Влажность почвы. Ручной ввод'
      },
      suffix: {
        en: 'mm',
        es: 'mm',
        ru: 'мм'
      },
      wilting_point: {
        en: 'Wilting point',
        es: 'Punto de marchitamiento',
        ru: 'Точка увядания'
      },
      start_watering: {
        en: 'Start Watering',
        es: 'Inicio del riego',
        ru: 'Начало полива'
      },
      stop_watering: {
        en: 'Stop Watering',
        es: 'Fin del riego',
        ru: 'Окончание полива'
      },
      smallest_field_capacity: {
        en: 'Smallest Field Capacity',
        es: 'Capacidad de humedad más baja',
        ru: 'Наименьшая влагоемкость'
      }
    },
    areaPlan: {
      title: {
        en: 'Plans',
        es: 'Plan de riego',
        ru: 'План полива'
      },
      suffix: {
        en: 'm',
        es: 'm',
        ru: 'м'
      },
    },
    mapLayers: {
      titles: {
        en: 'Titles',
        es: 'Títulos',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        es: 'Campos',
        ru: 'Поля'
      },
    },
    mapProviders: {
      osm: {
        en: 'Open Street Map',
        es: 'Open Street Map',
        ru: 'Схема'
      },
      esri: {
        en: 'Esri',
        es: 'Esri',
        ru: 'Спутник'
      },
      titles: {
        en: 'Titles',
        es: 'Títulos',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        es: 'Campos',
        ru: 'Поля'
      },
      pivots: {
        en: 'Pivots',
        es: 'Pivots',
        ru: 'Машины'
      },
      meteostations: {
        en: 'Meteo Stations',
        es: 'Estaciones meteorológicas',
        ru: 'Метеостанции'
      },
      watermeters: {
        en: 'Water meters',
        es: 'Contadores de agua',
        ru: 'Датчики влажности'
      }
    },
    admin: {
      settings: {
        en: 'Settings',
        es: 'Ajustes',
        ru: 'Настройки'
      },
      deviceSettings: {
        en: 'Admin Device Settings',
        es: 'Configuración de acceso',
        ru: 'Настройки доступа'
      },
      menu: {
        users: {
          en: 'Users',
          es: 'Usuarios',
          ru: 'Пользователи'
        },
        organizations: {
          en: 'Organizations',
          es: 'Organizaciones',
          ru: 'Организации'
        },
        pivots: {
          en: 'Devices',
          es: 'Dispositivos',
          ru: 'Машины'
        },
        telegram: {
          en: 'Telegram',
          es: 'Telegram',
          ru: 'Телеграм'
        }
      },
      users: {
        id: {
          en: 'ID',
          es: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          es: 'Nombre',
          ru: 'Имя'
        },
        email: {
          en: 'Email',
          es: 'Email',
          ru: 'Электронная почта'
        },
        role: {
          en: 'Role',
          es: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          es: 'Organización',
          ru: 'Организация'
        },
        devices: {
          en: 'Devices',
          es: 'Dispositivos',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Users Available',
          es: 'No hay usuarios disponibles',
          ru: 'Список пользователей пуст'
        }
      },
      organizations: {
        id: {
          en: 'ID',
          es: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          es: 'Nombre',
          ru: 'Название'
        },
        devices: {
          en: 'Devices',
          es: 'Dispositivos',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Organizations Available',
          es: 'No hay organizaciones disponibles',
          ru: 'Список организаций пуст'
        }
      },
      pivots: {
        id: {
          en: 'ID',
          es: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          es: 'Nombre',
          ru: 'Название'
        },
        organization: {
          en: 'Organization',
          es: 'Organización',
          ru: 'Организация'
        },
        noDataText: {
          en: 'No Pivot Available',
          es: 'No hay pivot disponible',
          ru: 'Список машин пуст'
        }
      },
      user: {
        title: {
          en: 'User',
          es: 'Usuario',
          ru: 'Пользователь'
        },
        newTitle: {
          en: 'New User',
          es: 'Nuevo usuario',
          ru: 'Новый пользователь'
        }
      },
      organization: {
        title: {
          en: 'Organization',
          es: 'Organización',
          ru: 'Организация'
        },
        newTitle: {
          en: 'New Organization',
          es: 'Nueva organización',
          ru: 'Новая организация'
        }
      },
      pivot: {
        title: {
          en: 'Pivot',
          es: 'Pivot',
          ru: 'Машина'
        },
        newTitle: {
          en: 'New Pivot',
          es: 'Nueva pivot',
          ru: 'Новая Машина'
        }
      },
    },
    camera: {
      add: {
        en: 'Add Camera',
        es: 'Agregar cámara',
        ru: 'Добавить камеру'
      },
      edit: {
        en: 'Edit Camera Settings',
        es: 'Editar la configuración de la cámara',
        ru: 'Редактировать настройки камеры'
      }
    },
    pivot: {
      field: {
        en: 'Field',
        es: 'Campo',
        ru: 'Параметр'
      },
      value: {
        en: 'Value',
        es: 'Valor',
        ru: 'Значение'
      }
    },
    meteoTable: {
      dates: {
        en: 'Date',
        es: 'Fecha',
        ru: 'Дата',
      },
      Temperature: {
        en: 'Temperature',
        es: 'Temperatura',
        ru: 'Температура',
      },
      AirRh: {
        en: 'Air Rh',
        es: 'Humedad del aire',
        ru: 'Влажность воздуха',
      },
      Dewpoint: {
        en: 'Dewpoint',
        es: 'Punto de rocío',
        ru: 'Точка росы',
      },
      Precipitation: {
        en: 'Precipitation',
        es: 'Precipitación',
        ru: 'Осадки',
      },
      WindSpeed: {
        en: 'Wind speed',
        es: 'Velocidad del viento',
        ru: 'Скорость ветра',
      },
      max: {
        en: 'Max',
        es: 'Max',
        ru: 'Макс'
      },
      min: {
        en: 'Min',
        es: 'Min',
        ru: 'Мин'
      },
      avg: {
        en: 'Avg',
        es: 'Media',
        ru: 'Сред'
      },
      summa: {
        en: 'Summa',
        es: 'Suma',
        ru: 'Сумма'
      }
    },
    meteoSummary: {
      sat: {
        en: 'SAT',
        es: 'SAT',
        ru: 'Сумма акт. темп.'
      },
      satFormula: {
        en: 'The sum of all average daily temperatures exceeding',
        es: 'La suma de todas las temperaturas medias diarias superiores',
        ru: 'Сумма всех среднесуточных температур, превышающих'
      },
      gdd: {
        en: 'GDD',
        es: 'GDD',
        ru: 'Градусо-дни'
      },
      gddFormula: {
        en: 'The sum of the average daily temperatures exceeding the base value',
        es: 'La suma de las temperaturas medias diarias que superan el valor base',
        ru: 'Сумма среднесуточных температур, превышающих базовое значение'
      },
      chu: {
        en: 'CHU',
        es: 'CHU',
        ru: 'Всего единиц тепла  (CHU)'
      },
      chuFormula: {
        en: 'Crop Heat Units',
        es: 'Unidades de calor',
        ru: 'Единицы тепла (Crop Heat Units)'
      },
      numberDays: {
        en: 'Number of days to calculate',
        es: 'Número de días para el cálculo',
        ru: 'Количество дней для расчета'
      },
      above: {
        en: 'above',
        es: 'arriba',
        ru: 'выше'
      }
    },
    forecastData: {
      temperature_2m_max: {
        en: 't°C max',
        es: 't°C max',
        ru: 't°C max',
      },
      temperature_2m_min: {
        en: 't°C min',
        es: 't°C min',
        ru: 't°C min',
      },
      wind_speed_10m_max: {
        en: 'Wind',
        es: 'Viento',
        ru: 'Ветер',
      },
      precipitation_sum: {
        en: 'Precipitation',
        es: 'Precipitación',
        ru: 'Осадки',
      },
      precipitation_probability_max: {
        en: 'Probability',
        es: 'Probabilidad',
        ru: 'Вероятность',
      }
    },
    report: {
      pivot: {
        en: 'Pivot',
        es: 'Pivot',
        ru: 'Пивот'
      },
      organization: {
        en: 'Organization',
        es: 'Organización',
        ru: 'Организация'
      },
      date: {
        en: 'Date/Time',
        es: 'Fecha/Hora',
        ru: 'Дата/Время'
      },
      status: {
        en: 'Status',
        es: 'Status',
        ru: 'Статус'
      },
      position: {
        en: 'Position',
        es: 'Posición',
        ru: 'Позиция'
      },
      field: {
        en: 'Field',
        es: 'Campo',
        ru: 'Поле'
      },
      passed: {
        en: 'Distance',
        es: 'Distancia',
        ru: 'Пройдено'
      },
      speed: {
        en: 'Speed',
        es: 'Velocidad',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        es: 'Norma',
        ru: 'Норма'
      },
      duration: {
        en: 'Duration',
        es: 'Duración',
        ru: 'Длительность'
      },
      ha: {
        en: 'Ha',
        es: 'Ha',
        ru: 'Га'
      },
      flow: {
        en: 'Flujo de agua',
        es: 'Consumo de agua',
        ru: 'Расход воды'
      },
      bar: {
        en: 'Bar',
        es: 'Bar',
        ru: 'Давление'
      },
      motohours: {
        en: 'Moto Hours',
        es: '',
        ru: 'Моточасы'
      },
      motohourMax: {
        en: 'Moto Hours Limit',
        es: '',
        ru: 'Порог'
      },
      hour: {
        en: 'hour ',
        es: 'hora ',
        ru: 'час '
      },
      day: {
        en: 'day ',
        es: 'día ',
        ru: 'день '
      },
      days: {
        en: 'days ',
        es: 'días ',
        ru: 'дня '
      },
      days2: {
        en: 'days ',
        es: 'días ',
        ru: 'дней '
      },
      hours: {
        en: 'hours ',
        es: 'horas ',
        ru: 'часов '
      },
      hours2: {
        en: 'hours ',
        es: 'horas ',
        ru: 'часа '
      },
      min: {
        en: 'min ',
        es: 'min ',
        ru: 'мин '
      },
      sec: {
        en: 'sec ',
        es: 'sec ',
        ru: 'сек '
      },
      dryTrack: {
        en: 'Movement without water',
        es: 'Movimientos sin agua',
        ru: 'Движения без воды (Сухой след)'
      },
      totalTravelTimeWithWater: {
        en: 'Total travel time with water',
        es: 'Tiempo total de movimiento con agua',
        ru: 'Общее время движения с водой'
      },
      totalTravelTimeWithFert: {
        en: 'Total travel time with fertilizers',
        es: 'Tiempo total de viaje con fertilizantes',
        ru: 'Общее время движения с удобрениями'
      },
      areaDryTrack: {
        en: 'Area of a dry track',
        es: 'Área del camino recorrido sin agua',
        ru: 'Площадь сухого следа'
      },
      areaWetTrackWater: {
        en: 'Area of a wet track with water',
        es: 'Área del camino recorrido con agua',
        ru: 'Площадь мокрого следа с водой'
      },
      areaWetTrackFert: {
        en: 'Area of a wet track with fertilizers',
        es: 'Área del camino recorrido con fertilizers',
        ru: 'Площадь мокрого следа с удобрениями'
      },
      totalArea: {
        en: 'Total Area',
        es: 'Área total',
        ru: 'Общая площадь'
      },
      totalTrack: {
        en: 'Total Track Area',
        es: 'Distancia total recorrida',
        ru: 'Общий пройденный путь'
      },
      totalWater: {
        en: 'Total water consumption',
        es: 'Consumo total de agua',
        ru: 'Общий расход воды'
      },
      totalTraveled: {
        en: 'Total distance traveled',
        es: 'Distancia total recorrida',
        ru: 'Общий пройденный путь'
      },
      serviceStop: {
        en: 'Service Stop',
        es: 'Parada de servicio',
        ru: 'Остановка для обслуживания',
      },
      moveFwd: {
        en: 'Moving forward',
        es: 'Moviéndose hacia adelante',
        ru: 'Движение вперед',
      },
      moveFwdWtr: {
        en: 'Moving forward with water',
        es: 'Moviéndose hacia adelante con el agua',
        ru: 'Движение вперед с водой',
      },
      moveFwdFrt: {
        en: 'Moving forward with fertilizer',
        es: 'Moviéndose hacia adelante con los fertilizers',
        ru: 'Движение вперед с удобрением',
      },
      moveRev: {
        en: 'Moving backwards',
        es: 'Moviéndose hacia atrás',
        ru: 'Движение назад',
      },
      moveRevWtr: {
        en: 'Moving backwards with water',
        es: 'Moviéndose hacia atrás con el agua',
        ru: 'Движение назад с водой',
      },
      moveRevFrt: {
        en: 'Moving backwards with fertilizer',
        es: 'Moviéndose hacia atrás con los fertilizers',
        ru: 'Движение назад с удобрением',
      },
      moveStopped: {
        en: 'Moving is stopped',
        es: 'El movimiento se ha detenido',
        ru: 'Движение прекращено',
      },
      stopped: {
        en: 'Stopped',
        es: 'Parando',
        ru: 'Остановка',
      },
      waiting: {
        en: 'Waiting',
        es: 'Expectativa',
        ru: 'Ожидание',
      },
      automaticStopBarrier: {
        en: 'Automatic stop at the barrier',
        es: 'Parada automática en la barrera',
        ru: 'Автоматическая остановка у барьера',
      },
      softBarrier: {
        en: 'Soft Barrier',
        es: 'Barrera blanda',
        ru: 'Мягкий барьер',
      },
      stopWithError: {
        en: 'Stop with error',
        es: 'Movimiento finalizado con error',
        ru: 'Движение прекращено с ошибкой'
      },
      safetyError: {
        en: 'Safety error',
        es: 'Error de seguridad',
        ru: 'Ошибка цепи безопасности'
      },
      noElectric: {
        en: 'No electricity',
        es: 'Sin electricidad',
        ru: 'Отсутствие напряжения'
      },
      alignmentError: {
        en: 'Alignment error',
        es: 'Error de alineación',
        ru: 'Ошибка выравнивания'
      },
      pressureRecovery: {
        en: 'Pressure recovery',
        es: 'Recuperación de presión',
        ru: 'Восстановление давления'
      },
      lowPressureError: {
        en: 'Low pressure error',
        es: 'Error de baja presión',
        ru: 'Ошибка низкого давления'
      },
      minAllowPressure: {
        en: 'Minimum allowable pressure',
        es: 'Presión mínima admisible',
        ru: 'Минимально допустимое давление'
      },
      pressureWaitingDelay: {
        en: 'Pressure waiting delay',
        es: 'Retardo de espera por presión',
        ru: 'Задержка ожидания давления'
      },
      gpsError: {
        en: 'GPS Error',
        es: 'Error de GPS',
        ru: 'Ошибка GPS'
      },
      someWarning: {
        en: 'Warning',
        es: 'Предупреждения',
        ru: 'Предупреждения'
      },
      someError: {
        en: 'Error',
        es: 'Error',
        ru: 'Ошибка'
      },
      totalDowntime: {
        en: 'Total downtime',
        es: 'Tiempo de inactividad total',
        ru: 'Общее время простоя'
      }
    },
    backEndStatuses: {
      alg_err: {
        en: 'Alignment error',
        es: 'Error de alineación',
        ru: 'Ошибка выравнивания'
      },
      lic_error: {
        en: 'License error',
        es: 'Error de licencia',
        ru: 'Ошибка лицензии Valley'
      },
      enrg_low: {
        en: 'Low Energy Error',
        es: 'Error de baja energía',
        ru: 'Низкое напряжение'
      },
      prsr_low: {
        en: 'Low pressure',
        es: 'Baja presión',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'Restart delay',
        es: 'Retraso de reinicio',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'Pressure waiting delay',
        es: 'Retardo de espera por presión',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'Pressure recovery',
        es: 'Recuperación de presión',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'Minimum allowable pressure',
        es: 'Presión mínima admisible',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        es: 'Over Water',
        ru: 'Over Water'
      },
      prepare: {
        en: 'Prepare',
        es: 'Preparación',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        es: 'Error de GPS',
        ru: 'Ошибка GPS'
      },
      sft_error: {
        en: 'Safety Error',
        es: 'Error de seguridad',
        ru: 'Цепь безопасности'
      },
      voltage_error: {
        en: 'Voltage Error',
        es: 'Error de voltaje',
        ru: 'Отсутствие напряжения'
      },
      barrier_stop: {
        en: 'Barrier Stop',
        es: 'Parada de barrera',
        ru: 'Автоматическая остановка у барьера'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        es: 'Error del sensor de posición',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        es: 'Error de temperatura',
        ru: 'Ошибка температуры'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        es: 'Barrera blanda',
        ru: 'Мягкий барьер'
      },
      pump_station_alarm: {
        en: 'Pump Station Alarm',
        es: 'Falla de la estación de bombeo',
        ru: 'Авария насосной станции'
      },
      pump_alarm: {
        en: 'Pump Alarm',
        es: 'Alarma de pompa',
        ru: 'Авария насоса'
      }
    },
    filter: {
      date_from: {
        en: 'Date From',
        es: 'Fecha desde',
        ru: 'Дата от',
      },
      date_to: {
        en: 'Date To',
        es: 'Fecha hasta',
        ru: 'Дата до',
      },
      time_from: {
        en: 'Time From',
        es: 'Tiempo desde',
        ru: 'Время с',
      },
      time_to: {
        en: 'Time To',
        es: 'Es hora de',
        ru: 'Время до',
      },
      period: {
        en: 'Period',
        es: 'Período',
        ru: 'Период',
      },
      hour: {
        en: 'Hour',
        es: 'Hora',
        ru: 'Час',
      },
      day: {
        en: 'Day',
        es: 'Día',
        ru: 'День',
      }
    },
    field_station: {
      en: 'Field Station',
      es: 'Estación de campo',
      ru: 'Полевая',
    },
    meteo_station: {
      en: 'Meteo Station',
      es: 'Meteo',
      ru: 'Метео',
    },
    charts: {
      watermark: {
        tab: {
          en: 'Watermark',
          es: 'Humedad del suelo',
          ru: 'Влажность почвы',
        },
        soil_wet: {
          en: 'Soil Moisture',
          es: 'Humedad del suelo',
          ru: 'Влажность почвы'
        },
        wet: {
          en: 'Air Rh',
          es: 'Humedad del aire',
          ru: 'Влажность воздуха'
        },
        precipitation: {
          en: 'Precipitation',
          es: 'Precipitación',
          ru: 'Осадки'
        },
        temperature: {
          en: 'Soil temperature',
          es: 'Temperatura del suelo',
          ru: 'Температура почвы'
        },
        vic: {
          en: 'Volumetric Ionic Content',
          es: 'Salinidad del suelo',
          ru: 'Засоленность почвы'
        },
      },
      air_temperature: {
        en: 'Air Temperature',
        es: 'Temperatura del agua',
        ru: 'Температура воздуха'
      },
      solar_radiation: {
        en: 'Solar radiation',
        es: 'Radiación solar',
        ru: 'Солнечная радиация'
      },
      wind_speed: {
        en: 'Wind Speed',
        es: 'Velocidad del viento',
        ru: 'Скорость ветра'
      },
      daily_et0: {
        en: 'Daily ET0',
        es: 'ET0 diario',
        ru: 'Дневная ET0'
      },
      dew_point: {
        en: 'Dew Point',
        es: 'Punto de rocío',
        ru: 'Точка росы'
      },
      meteo: {
        tab: {
          en: 'Monitoring',
          es: 'Datos meteorológicos',
          ru: 'Метеоданные'
        }
      },
      sensor_calibration: {
        en: 'Sensor calibration',
        es: 'Calibración de sensores',
        ru: 'Калибровка датчиков'
      },
      units: {
        mm: {
          en: 'mm',
          es: 'mm',
          ru: 'мм'
        },
        cm: {
          en: 'cm',
          es: 'cm',
          ru: 'см'
        },
        ms: {
          en: 'm/s',
          es: 'm/s',
          ru: 'м/с'
        },
        ha: {
          en: 'Ha',
          es: 'Ha',
          ru: 'Га'
        },
        bar: {
          en: 'Bar',
          es: 'Bar',
          ru: 'Бар'
        },
        mj: {
          en: 'MJ/m',
          es: 'MJ/m',
          ru: 'МДж/м',
        },
        m3: {
          en: 'm³',
          es: 'm³',
          ru: 'м³'
        }
      }
    },
    barrier: {
      title: {
        en: 'Barrier',
        es: 'Barrera',
        ru: 'Барьер'
      },
      between: {
        en: 'Between',
        es: '',
        ru: 'Участок'
      }
    },
    weatherCode: {
      'clear-sky': {
        en: 'Clear Sky',
        es: 'Cielo despejado',
        ru: 'Ясно',
      },
      'mainly-clear': {
        en: 'Mainly Сlear',
        es: 'Cielo despejado',
        ru: 'Ясно',
      },
      'partly-cloudy': {
        en: 'Cloudy',
        es: 'Nublado',
        ru: 'Облачно',
      },
      'overcast': {
        en: 'Overcast',
        es: 'Nublado',
        ru: 'Пасмурно',
      },
      'fog': {
        en: 'Fog',
        es: 'Niebla',
        ru: 'Туман',
      },
      'drizzle': {
        en: 'Drizzle',
        es: 'Llovizna',
        ru: 'Морось',
      },
      'freezing-drizzle': {
        en: 'Freezing Drizzle',
        es: 'Helada',
        ru: 'Изморозь',
      },
      'rain-slight': {
        en: 'Rain Slight',
        es: 'Lluvia ligera',
        ru: 'Слабый дождь',
      },
      'rain-moderate': {
        en: 'Rain',
        es: 'Lluvia',
        ru: 'Дождь',
      },
      'rain-heavy-intensity': {
        en: 'Rain Heavy',
        es: 'Lluvia Pesada',
        ru: 'Сильный дождь',
      },
      'freezing-rain-light': {
        en: 'Freezing Rain',
        es: 'Aguanieve',
        ru: 'Дождь со снегом',
      },
      'freezing-rain-heavy-intensity': {
        en: 'Freezing Rain',
        es: 'Aguanieve',
        ru: 'Дождь со снегом',
      },
      'snow-slight': {
        en: 'Snow',
        es: 'Nieve',
        ru: 'Снег',
      },
      'snow-fall-moderate': {
        en: 'Snow Fall',
        es: 'Nevada',
        ru: 'Снегопад',
      },
      'snow-fall-heavy-intensity': {
        en: 'Snow Fall',
        es: 'Nevada',
        ru: 'Снегопад',
      },
      'snow-grains': {
        en: 'Snow Grains',
        es: 'Granizo',
        ru: 'Град',
      },
      'rain-showers-slight': {
        en: 'Rain Showers',
        es: 'Ducha',
        ru: 'Ливень',
      },
      'rain-showers-moderate': {
        en: 'Rain Showers',
        es: 'Ducha',
        ru: 'Ливень',
      },
      'rain-showers-heavy-intensity': {
        en: 'Rain Showers',
        es: 'Ducha',
        ru: 'Ливень',
      },
      'snow-showers-slight': {
        en: 'Snow Showers',
        es: 'Nevada',
        ru: 'Снегопад',
      },
      'snow-showers-heavy': {
        en: 'Snow Showers',
        es: 'Nevada',
        ru: 'Снегопад',
      },
      'thunderstorm': {
        en: 'Thunderstorm',
        es: 'Tormenta',
        ru: 'Гроза',
      },
      'thunderstorm-slight-hail': {
        en: 'Thunderstorm',
        es: 'Tormenta',
        ru: 'Гроза',
      },
      'thunderstorm-heavy-hail': {
        en: 'Thunderstorm',
        es: 'Tormenta',
        ru: 'Гроза',
      },
    },
    monitoring: {
      actualData: {
        en: 'Actual data',
        es: 'Datos actuales',
        ru: 'Актуальные данные'
      },
      archiveData: {
        en: 'Archive data',
        es: 'Datos de archivo',
        ru: 'Архивные данные'
      },
      summaryActiveTemp: {
        en: 'Summary of active temperatures',
        es: 'Resumen de temperaturas activas',
        ru: 'Сводка по активным температурам'
      },
      summary: {
        en: 'Summary',
        es: 'Resumen',
        ru: 'Сводка'
      },
      forecastFor: {
        en: 'Forecast for',
        es: 'Pronóstico de',
        ru: 'Прогноз на',
      },
      days: {
        en: 'days',
        es: 'días',
        ru: 'дней'
      },
      numberOf: {
        en: 'Number of',
        es: 'Cantidad',
        ru: 'Количество'
      },
      updated: {
        en: 'Updated',
        es: 'Actualizado',
        ru: 'Обновлено'
      }
    },
    summary: {
      maxTemp: {
        en: 'Max temp',
        es: 'Max temp',
        ru: 'Макс. температура'
      },
      minTemp: {
        en: 'Min temp',
        es: 'Min temp',
        ru: 'Мин. температура'
      },
      avgTemp: {
        en: 'Avg temp',
        es: 'Prom temp',
        ru: 'Сред. температура'
      },
      maxMoisture: {
        en: 'Max moisture',
        es: 'Max humedad',
        ru: 'Макс. влажность',
      },
      minMoisture: {
        en: 'Min moisture',
        es: 'Min humedad',
        ru: 'Мин. влажность',
      },
      avgMoisture: {
        en: 'Avg moisture',
        es: 'Prom humedad',
        ru: 'Сред. влажность',
      },
      totalPrecipitation: {
        en: 'Total precipitation',
        es: 'Precipitación total',
        ru: 'Итого осадков',
      },
      windSpeedEvg: {
        en: 'Wind Speed Mean',
        es: 'Vel. media del viento',
        ru: 'Ср. скорость ветра',
      },
    },
    scada: {
      title: {
        en: 'Pumping station indicators',
        es: 'Indicadores de la estación de bombeo',
        ru: 'Показатели насосной станции',
      },
      pump: {
        en: 'Pump',
        es: 'Pompa',
        ru: 'Насос'
      },
      pumpOn: {
        en: 'TURNED ON',
        es: 'ENCENDIDO',
        ru: 'РАБОТАЕТ',
      },
      pumpOff: {
        en: 'TURNED OFF',
        es: 'APAGADO',
        ru: 'ВЫКЛЮЧЕН',
      },
      status: {
        initial_filling: {
          en: 'Initial filling',
          es: 'Initial filling',
          ru: 'Начальное заполнение',
        }
      }
    },
    timeUntilServiceStop: {
      en: 'Travel time to service stop or to end of field',
      es: 'Tiempo de viaje hasta la parada de servicio o hasta el final del campo',
      ru: 'Время движения до сервисной остановки или до конца гона',
    },
    timeUntilEndFieldWithHoseStop: {
      en: 'Travel time to the end of the field, taking into account the switching of hydrants',
      es: 'Tiempo de recorrido hasta el final del campo, teniendo en cuenta el cambio de hidrantes',
      ru: 'Время движения до конца гона, с учетом переключения гидрантов',
    },
    compass: {
      N: {
        en: 'North',
        es: 'Norte',
        ru: 'Север'
      },
      NNE: {
        en: 'NNE',
        es: 'NNE',
        ru: 'ССВ'
      },
      NE: {
        en: 'NE',
        es: 'NE',
        ru: 'СВ'
      },
      ENE: {
        en: 'ENE',
        es: 'ENE',
        ru: 'ВСВ'
      },
      E: {
        en: 'East',
        es: 'Este',
        ru: 'Восток'
      },
      ESE: {
        en: 'ESE',
        es: 'ESE',
        ru: 'ВЮВ'
      },
      SE: {
        en: 'SE',
        es: 'SE',
        ru: 'ЮВ'
      },
      SSE: {
        en: 'SSE',
        es: 'SSE',
        ru: 'ЮЮВ'
      },
      S: {
        en: 'South',
        es: 'Sur',
        ru: 'Юг'
      },
      SSW: {
        en: 'SSW',
        es: 'SSW',
        ru: 'ЮЮЗ'
      },
      SW: {
        en: 'SW',
        es: 'SW',
        ru: 'ЮЗ'
      },
      WSW: {
        en: 'WSW',
        es: 'WSW',
        ru: 'ЗЮЗ'
      },
      W: {
        en: 'West',
        es: 'Oeste',
        ru: 'Запад'
      },
      WNW: {
        en: 'WNW',
        es: 'ONO',
        ru: 'ЗСЗ'
      },
      NW: {
        en: 'NW',
        es: 'NO',
        ru: 'СЗ'
      },
      NNW: {
        en: 'NNW',
        es: 'NNO',
        ru: 'ССЗ'
      }
    }
  }
}
