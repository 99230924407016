<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts" />
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {
      sides: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']
    }
  },
  computed: {
    showChart () {
      return this.data['Wind direction'] && !_.isEmpty(this.data['Wind direction'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          polar: true          
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        pane: {
          // startAngle: 0,
          // endAngle: 360
        },
        xAxis: {
          // tickInterval: 45,
          // min: 0,
          // max: 360,
          tickmarkPlacement: 'on',
          categories: this.compass(),
          // categories: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
          // labels: {
          //   format: '{value}°'
          // }
        },
        yAxis: {
          tickInterval: 10,
          min: 0,
          labels: {
            formatter: function () {
              if (this.value > 0) {
                return this.value + '%';
              }
            }
          },
        },
        // plotOptions: {
        //   series: {
        //     animation: false,
        //     pointStart: 0,
        //     pointInterval: 45
        //   },
          // column: {
          //   column: {
          //     pointPadding: 0,
          //     groupPadding: 0
          //   }
          // },
        // },
        plotOptions: {
          series: {
              animation: false,
              stacking: 'normal',
              shadow: false,
              groupPadding: 0,
              pointPlacement: 'on',
              
          }
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return this.y + '%';
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          type: 'area',
          // name: 'Скорость, мс',
          data: this.data['Wind direction'],
        }],

        

        // series: [{
        //   yAxis: 0,
        //   zIndex: 5,
        //   events:{
        //     legendItemClick: function () {
        //       return false
        //     }
        //   },
        //   data: this.data,
        //   enableMouseTracking: false,
        //   type: 'areaspline',
        //   lineColor: 'rgba(255, 255, 153, 0.75)',
        //   color: 'rgba(255, 255, 153, 0.75)',
        //   lineWidth: 1,
        //   marker: {
        //     symbol: 'circle',
        //     enabled: true
        //   }
        // }]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
    compass () {
      return this.sides.map((val) => {
        return this.$t('compass.' + val)
      })
    },
  }
}
</script>
