<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      
      <template>
        <v-card class="my-3 elevation-1">
          <v-card-title>
            <v-row>
              <v-col class="title">
                {{ $t('watermark.title') }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="!chartData.Watermarks">
            <v-alert type="info" outlined>
              {{ $t('success.noWatermark') }}
            </v-alert>
          </v-card-text>
          <div class="body-1" v-else>
            <v-card-text>
              <v-row>
                <v-col>
                  <fields v-model="filterData" :fields="filterFields" />
                </v-col>
                <v-col cols="auto" class="mt-3">
                  <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <v-card-text>
            <WatermarkChart v-if="chartData.Watermarks"
                :data="chartData.Watermarks" 
                :dates="chartData.dates"
                :provider="chartData.prov" 
                :reversed="chartData.prov === 1"
                :soil="chartData['Soil']"
              />
          </v-card-text>
          <v-card-text>
            <table v-if="instance.table" class="fc-map__table">
              <thead>
                <tr>
                  <th>
                    {{ $t('form.watermarkDate') }}
                  </th>
                  <th>
                    10 {{ $t('charts.units.cm') }}
                  </th>
                  <th>
                    30 {{ $t('charts.units.cm') }}
                  </th>
                  <th>
                    50 {{ $t('charts.units.cm') }}
                  </th>
                  <th v-if="editingInstance" class="fc-map__table-cell -remove-control" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in instance.table" :key="i" class="fc-map__table-row">
                  <td class="fc-map__table-cell">
                    <field-control v-model="instance.table[i][0].date" :disabled="!editingInstance" :field="fieldsRev.date" />
                  </td>
                  <td v-for="(watermark, index) in row" :key="index"  class="fc-map__table-cell">
                    <field-control v-model="watermark.value" :disabled="!editingInstance" :field="fieldsRev.value" />
                  </td>
                  
                  <td v-if="editingInstance" class="fc-map__table-cell -remove-control">
                    <v-btn icon @click="removeWatermarkValue(i)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="editingInstance" class="d-flex justify-end ma-2">
              <v-btn icon @click="addWatermarkValue(instance.table)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap justify-end">
        <template v-if="editingInstance">
          <v-btn :loading="loading" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :loading="loading" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </template>
        <template v-else>
          <v-btn text @click="start">{{ $t('actions.edit') }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import WatermarkChart from '~/components/chart/WatermarkChart'

export default {
  props: ['id', 'tab'],
  components: {WatermarkChart},
  mixins: [generalFormMixin],
  data () {
    return {
      chartData: {},
      filterData: {
        from: null,
        to: null
      },
      instance: [],
      watermarks: [],
      editingInstance: false,
      maxCount: 200
    }
  },
  computed: {
    fields () { return configFields.watermark },
    filterFields () { return configFields.watermarkFilter },
    fieldsRev () {
      return this.fields.reduce((out, field) => {
        out[field.key] = field
        return out
      }, {})
    },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item})
    },
    resetEditingInstanceTrigger () {
      return this.id + this.tab
    }
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.prepareWatermarkInstance(this.item)
      }
    },
    resetEditingInstanceTrigger: 'cancel'
  },
  methods: {
    prepareWatermarkInstance (item) {
      this.chartData = {}
      if (item && item.watermark && item.watermark.dates) {
        const instance = _.cloneDeep(item.watermark)
        this.chartData = item.watermark

        return instance
      } else {
        return []
      }
    },
    prepareWatermarkFormData (data) {
      if (!data || !data.length) { return [] }
      data = _.cloneDeep(data)
      data.forEach((watermark) => {
        if (watermark && !watermark.length) {
          // delete hoseStop
        } else {
          watermark = watermark.map((key, value) => ({
            key: value.toString(),
          }))
        }
      })
      return data
    },
    addWatermarkValue (watermark) {
      if (!watermark) {
        watermark = []
      }

      watermark.push([{
        date: 0,
        depth: 10,
        value: null,
      },{
        date: 0,
        depth: 30,
        value: null,
      },{
        date: 0,
        depth: 50,
        value: null,
      }])
    },
    removeWatermarkValue (index) {
      if (this.instance.table && this.instance.table.length) {
        this.instance.table.splice(index, 1)
      }
    },
    start () {
      this.editingInstance = true
    },
    save () {
      this.setLoading(true)
      const data = this.prepareWatermarkFormData(this.instance.table)
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateWatermark', data: {deviceId: this.id, data}}))
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.watermarkUpdated')})
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel () {
      this.instance = this.prepareWatermarkInstance(this.item)
      this.editingInstance = false
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.filterData, this.filterFields)
      return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.request.watermark', data: {id: this.id, data: data}}))
          .then((response) => {
            this.chartData = response.data
            this.instance = this.prepareWatermarkInstance(response.data)
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })

    },
  }
}
</script>
