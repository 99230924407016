<template>
  <div v-if="id && item" class="fc-map__page -with-header">
    <div class="fc-map__page-header">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-btn icon @click="selectNextItem(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu offset-y min-width="100" max-height="200">
            <template v-slot:activator="{on}">
              <v-btn text v-on="on">
                <h1 class="title">
                  {{ item.name }}
                </h1>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="listItem in items"
                :key="listItem.id"
                :class="{'v-item--active v-list-item--active': listItem.id === id}"
                @click="selectItem(listItem)"
              >
                <v-list-item-title>
                  {{ listItem.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="selectNextItem(+1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div cols="auto">
          <div class="d-flex align-center">
            <item-alert :item="item" mode="tooltip" left />
            <item-admin v-if="adminDialogEnabled" :id="id">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
            </item-admin>
            <v-btn icon @click="centerItem">
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
            <v-btn v-if="showSecurity" icon exact>
              <item-security :item="item" />
            </v-btn>
            <v-btn v-if="showRelayConnection" icon exact>
              <relay-online :item="item" />
            </v-btn>
            <v-btn v-if="showRelayHfConnection" icon exact>
              <relay-hf-online :item="item" />
            </v-btn>
            <v-btn icon exact>
              <item-online :item="item" />
            </v-btn>
            <v-btn :to="{name: 'main'}" icon exact>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-tabs
      :value="tab"
      grow
      dark
      show-arrows
      background-color="grey darken-2"
      class="fc-map__page-tabs-container"
      @change="setTab"
    >
      <v-tabs-slider />
      <v-tab key="main">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.settings') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-rotate-left
        </v-icon>
      </v-tab>
      <v-tab key="guns" v-if="!isReadOnly">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.guns') }}
        </span>
        <img class="hidden-sm-and-up" :src="require('~/assets/icons/icon-gun.svg')" width="20">
      </v-tab>
      <v-tab v-if="type === 'lateral' && !isReadOnly" key="hoseStops">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.hoseStop') }}
        </span>
        <img class="hidden-sm-and-up" :src="require('~/assets/icons/icon-gun.svg')" width="20">
      </v-tab>
      <v-tab v-if="showAreaPlan && !isReadOnly" key="areaPlans">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.areaPlan') }}
        </span>
        <img class="hidden-sm-and-up" :src="require('~/assets/icons/icon-piechart.svg')" width="20">
      </v-tab>
      <v-tab v-if="showBarrier && !isReadOnly" key="barrier">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.barrierSettings') }}
        </span>
        <img class="hidden-sm-and-up" :src="require('~/assets/icons/icon-barrier.svg')" width="20">

      </v-tab>
      <v-tab key="watermark" v-if="!isReadOnly">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.watermark') }}
        </span>
        <img class="hidden-sm-and-up" :src="require('~/assets/icons/icon-watermark.svg')" width="20">
      </v-tab>
      <v-tab key="map" v-if="!isReadOnly">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.map') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-map-marker
        </v-icon>
      </v-tab>
      <v-tab key="camera" v-if="!isReadOnly">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.camera') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-camera
        </v-icon>
      </v-tab>
      <!-- <v-tab key="ndvi">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.ndvi') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-camera
        </v-icon>
      </v-tab> -->
      <v-tab v-if="showPivot && !isReadOnly" key="pivot">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.systemSettings') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-cog
        </v-icon>
      </v-tab>
      
      <!-- <v-tab v-if="isAdmin" key="report">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.reports') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-cog
        </v-icon>
      </v-tab> -->
      <!-- <v-tab v-if="isAdmin" key="report">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.history') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-cog
        </v-icon>
      </v-tab> -->
      <!-- <v-tab key="log" v-if="isFC">
        <span class="hidden-xs-only">
          {{ $t('mainMenu.log') }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-cog
        </v-icon>
      </v-tab> -->

      <v-tab-item key="main">
        <item-primary :id="id" :tab="tab" @request="request" />
      </v-tab-item>
      <v-tab-item key="guns">
        <item-end-guns :id="id" :tab="tab" @request="request" />
      </v-tab-item>
      <v-tab-item v-if="type === 'lateral'" key="hoseStops">
        <item-hose-stops :id="id" :tab="tab" @request="request" />
      </v-tab-item>
      <v-tab-item v-if="showAreaPlan && !isReadOnly" key="areaPlans">
        <item-area-plans :id="id" :tab="tab" @request="request" />
      </v-tab-item>
      <v-tab-item v-if="showBarrier" key="barrier">
        <item-barrier :id="id" :tab="tab" />
      </v-tab-item>
      <v-tab-item key="watermark">
        <item-watermark :id="id" :tab="tab" @request="request" />
      </v-tab-item>
      <v-tab-item key="map">
        <item-map :id="id" :tab="tab" />
      </v-tab-item>
      <v-tab-item key="camera">
        <item-camera :id="id" :tab="tab" />
      </v-tab-item>
      <!-- <v-tab-item key="ndvi">
        <item-ndvi :id="id" :tab="tab" />
      </v-tab-item> -->
      <v-tab-item v-if="showPivot" key="pivot">
        <item-pivot :id="id" :tab="tab" />
      </v-tab-item>
      
      <!-- <v-tab-item v-if="isAdmin" key="report">
        <item-report :id="id" :tab="tab" @request="request" />
      </v-tab-item> -->
      <!-- <v-tab-item v-if="isAdmin" key="history">
        <item-history :id="id" :tab="tab" @request="request" />
      </v-tab-item> -->
      <!-- <v-tab-item key="log" v-if="isFC">
        <item-log :id="id" :tab="tab" @request="request" />
      </v-tab-item> -->
    </v-tabs>
    <div class="fc-map__page-footer" />
  </div>
</template>

<script>
import ItemPrimary from '~/components/item/Primary'
import ItemEndGuns from '~/components/item/EndGuns'
import ItemHoseStops from '~/components/item/HoseStops'
import ItemAreaPlans from '~/components/item/AreaPlans'
import ItemWatermark from '~/components/item/Watermark'
import ItemMap from '~/components/item/Map'
import ItemCamera from '~/components/item/Camera'
// import ItemNdvi from '~/components/item/Ndvi'
// import ItemReport from '~/components/item/Report'
// import ItemHistory from '~/components/item/History'
// import ItemLog from '~/components/item/Log'
import ItemAdmin from '~/components/item/Admin'
import ItemPivot from '~/components/item/Pivot'
import ItemBarrier from '~/components/item/Barrier'
import ItemAlert from '~/components/map/ItemAlert'
import ItemOnline from '~/components/map/ItemOnline'
import ItemSecurity from '~/components/map/ItemSecurity'
import RelayOnline from '~/components/map/RelayOnline'
import RelayHfOnline from '~/components/map/RelayHfOnline'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  components: {ItemPrimary, ItemEndGuns, ItemHoseStops, ItemAreaPlans, ItemWatermark, ItemMap, ItemCamera, ItemOnline, RelayOnline, RelayHfOnline, ItemAdmin, ItemPivot, ItemBarrier, ItemAlert, ItemSecurity},
  mixins: [generalFormMixin],
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    isReadOnly () { return this.$store.getters['auth/isReadOnly'] },
    adminDialogEnabled () {
      return this.isAdmin && this.$store.state.auth.user.permissions.includes('Add access to Organization')
    },
    id () { return Number(this.$route.query.id) || 0 },
    tab () { return Number(this.$route.query.tab) || 0 },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    type () { return this.item?.displaySettings?.type || 'circle' },
    isFC() {
      if (
        this.item &&
        this.item.manufacturer === "FC"
      ) {
        return true;
      } else {
        return false;
      }
    },
    showPivot () {
      return this.item && this.item.deviceSettings && this.item.deviceSettings.settings && !_.isEmpty(this.item.deviceSettings.settings)
    },
    showBarrier () {
      return this.isFC
    },
    showAreaPlan () {
      return this.item && this.item.deviceSettings 
      // && (this.item.organization.id == 8 || this.item.organization.id == 12)
      && this.item.deviceSettings.areaPlans && !_.isEmpty(this.item.deviceSettings.areaPlans)
    },
    showRelayConnection () {
      return this.item.relays ?? false
    },
    showRelayHfConnection () {
      return this.item.relay_hf ?? false
    },
    showWatermark () {
      return this.item.watermark ?? false
    },
    showSecurity () {
      return this.item.guards ?? false
    }
  },
  watch: {
    id (value) {
      if (!value) {
        // this.$bus.$emit('map.center')
      } else {
        this.centerItem()
      }
    }
  },
  mounted () {
    if (this.id) {
      this.centerItem()
    } else {
      this.$bus.$emit('map.center')
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    selectItem (item) {
      this.$router.push({name: 'main', query: {id: item.id, tab: this.tab}})
    },
    setTab (tab) {
      this.$router.push({name: 'main', query: {id: this.id, tab}})
    },
    selectNextItem (direction) {
      if (!this.id || !this.items.length) {
        return false
      }
      let itemIndex = this.items.findIndex(item => item.id === this.id)
      itemIndex = (itemIndex || 0) + direction
      while (itemIndex < 0) { itemIndex += this.items.length }
      while (itemIndex >= this.items.length) { itemIndex -= this.items.length }
      this.selectItem(this.items[itemIndex])
    },
    centerItem () {
      if (this.item && this.item.displaySettings) {
        if (this.item.displaySettings.type === 'circle' && this.item.displaySettings.center) {
          this.$bus.$emit('map.center', this.item.displaySettings.center)
        } else if (this.item.displaySettings.type === 'lateral' && this.item.displaySettings.path_coords) {
          const coords = this.item.displaySettings.path_coords
          this.$bus.$emit('map.center', {lat: coords.start_lat, lng: coords.start_lng}, {lat: coords.end_lat, lng: coords.end_lng})
        }
      }
    },
    request ({type, callback}) {
      this.setLoading(true)
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.request.' + type, data: {id: this.id}}, {root: true}))
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 2000)
        }))
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$snack({type: 'success', message: this.$t('success.requestSent')})
          callback && callback()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
