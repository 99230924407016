<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <div class="fullscreen-wrapper">
      <highcharts v-if="showChart" :options="options" ref="highcharts" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    dates: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    showChart() {
      return this.data.Temperature && !_.isEmpty(this.data.Temperature)
    },
    options() {
      if (!this.showChart) {
        return null
      }
      // const maxValue = Math.max(...$.map(this.data.Temperature, val => val))
      // const minValue = Math.min(...$.map(this.data.Temperature, val => val))
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          crosshair: {
            width: 1,
            color: 'red'
          },
          // tickInterval: 1,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          // max: maxValue + (maxValue * 0.5),
          // min: minValue + (minValue * 0.5),
          startOnTick: true,
          endOnTick: true,
          title: {
            text: this.$t('charts.air_temperature') + ', °C',
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + '°';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
            pointWidth: 0,
            marker: {
              symbol: 'circle',
              enabled: false
            },
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            var s = '<b>' + this.x + '</b>';

            $.each(this.points, function () {
              s += '<br/><span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': ' +
                this.y + '°C';
            });

            return s;
          },
        },
        legend: {
          enabled: true
        },
        series: [{
          name: this.$t('meteoTable.max'),
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data.Temperature.max
        },
        {
          name: this.$t('meteoTable.avg'),
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data.Temperature.avg
        },
        {
          name: this.$t('meteoTable.min'),
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data.Temperature.min
        },
        ]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    }
  }
}
</script>
