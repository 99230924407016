<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-6">
      <v-alert v-if="!instance.length" type="info" outlined>
        {{ $t("success.noEndGuns") }}
      </v-alert>
      <template v-else>
        <v-card>
          <v-row class="pl-2">
            <v-col cols="12" sm="4">
              <map-item
                :item="mapItem"
                :icon-mode="{ lineWidth: 1, size: 260 }"
                :only-area-plan-segments="true"
                class="fc-map__map-item"
              />
            </v-col>
            <v-col cols="12" sm="8">
              <div class="title">
                {{ $t("areaPlan.title") }}
              </div>
              <div>
                <table class="area_plan_settings fc-map__table">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>
                        {{ $t("form.areaPlanStart") }}
                      </th>
                      <th>
                        {{ $t("form.areaPlanEnd") }}
                      </th>
                      <th>{{ $t("form.depth") }}</th>
                      <th>
                        {{ $t("form.areaPlanIrrigation") }}
                      </th>
                      <th v-if="editingInstance"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(areaPlan, index) in instance"
                      :key="index"
                      class="fc-map__table-row"
                    >
                      <td class="fc-map__table-cell">
                        {{ index + 1 }}
                      </td>
                      <td class="fc-map__table-cell" colspan="2" style="text-align:center"
                      v-if="areaPlan.delay"
                      >{{ $t('form.delay') }}</td>
                      <td class="fc-map__table-cell"
                      v-if="areaPlan.delay"
                      >
                        <field-control
                          v-model="areaPlan.delay"
                          :disabled="!editingInstance"
                          :field="fieldsRev.delay"
                          @input="updateDelayField(index)"
                        />
                      </td>
                      <td class="fc-map__table-cell" style="text-align:center"
                      v-if="areaPlan.delay"
                      >
                      <img :src="require('~/assets/icons/icon-stop.svg')"  class="fc-map__table-icon">
                      </td>
                      <td class="fc-map__table-cell"
                      v-if="!areaPlan.delay"
                      >
                        <field-control
                          v-model="areaPlan.start"
                          :disabled="!editingInstance"
                          :field="fieldsRev.start"
                          @input="updateInstanceField(index)"
                        />
                      </td>
                      <td class="fc-map__table-cell"
                      v-if="!areaPlan.delay"
                      >
                        <field-control
                          v-model="areaPlan.end"
                          :disabled="!editingInstance"
                          :field="fieldsRev.end"
                          @input="updateInstanceField(index)"
                        />
                      </td>
                      <!-- <td class="fc-map__table-cell"
                      v-if="!areaPlan.delay"
                      >
                        <field-control
                          v-model="areaPlan.speed"
                          :disabled="!editingInstance"
                          :field="fieldsRev.speed"
                        />
                      </td> -->
                      <td class="fc-map__table-cell"
                      v-if="!areaPlan.delay"
                      >
                        <field-control
                          v-model="areaPlan.depth"
                          :disabled="!editingInstance"
                          :field="fieldsRev.depth"
                          @input="updateSpeedField(index)"
                        />
                      </td>
                      <td
                      v-if="!areaPlan.delay"
                      >
                        <item-icon-controls
                          v-model="instance[index]"
                          :disabled="!editingInstance"
                          col-class="d-flex"
                        ></item-icon-controls>
                      </td>
                      <td
                        v-if="editingInstance"
                        class="fc-map__table-cell -remove-control"
                      >
                        <v-btn icon @click="removeAreaPlan(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="d-flex justify-end ma-2"
              >
                <v-btn v-if="addingDelay && editingInstance" icon @click="addDelay()">
                  <v-icon>mdi-pause</v-icon>
                </v-btn>
                <v-btn v-if="addingInstance && editingInstance" icon @click="addAreaPlan()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap justify-end">
        <template v-if="editingInstance">
          <v-btn :loading="loading" class="mr-3" text @click="cancel">{{
            $t("actions.cancel")
          }}</v-btn>
          <v-btn :loading="loading" color="primary" @click="save">{{
            $t("actions.save")
          }}</v-btn>
        </template>
        <template v-else>
          <!-- <v-btn v-if="isFC" :loading="loading" class="mr-3" text @click="request">{{ $t('actions.request') }}</v-btn> -->
          <v-btn text @click="start">{{ $t("actions.edit") }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import configFields from "~/config/fields";
import generalFormMixin from "~/components/mixins/generalFormMixin";
import ItemIconControls from "~/components/map/ItemIconControls3";
import MapItem from "~/components/map/MapItem";

export default {
  props: ["id", "tab"],
  components: { MapItem, ItemIconControls },
  mixins: [generalFormMixin],
  data() {
    return {
      instance: {},
      controls: { irrigation: "off", acc: "off" },
      editingInstance: false,
      addingInstance: false,
      addingDelay: false,
      maxAreaPlanCount: 20,
    };
  },
  computed: {
    fields() {
      configFields.areaPlan.forEach((field, index) => {
        if (field.key === "start" || field.key === "end") {
          configFields.areaPlan[index].min = this.startAngle;
          configFields.areaPlan[index].max = this.endAngle;
        }
      });

      return configFields.areaPlan;
    },
    fieldsRev() {
      return this.fields.reduce((out, field) => {
        out[field.key] = field;
        return out;
      }, {});
    },
    items() {
      return this.$store.getters["rtu/items"];
    },
    item() {
      return this.items.find((item) => item.id === this.id);
    },
    isFC() {
      return this.item.manufacturer === "FC" ? true : false;
    },
    startAngle() {
      return parseInt(this.item.displaySettings.startAngle);
    },
    endAngle() {
      return parseInt(this.item.displaySettings.endAngle);
    },
    updateInstanceTrigger() {
      return JSON.stringify({ id: this.id, item: this.item });
    },
    resetEditingInstanceTrigger() {
      return this.id + this.tab;
    },
    mapItem() {
      return {
        ...this.item,
        deviceSettings: {
          ...this.item.deviceSettings,
          areaPlans: this.instance,
        },
      };
    },
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler() {
        if (this.editingInstance) {
          return false;
        }
        this.instance = this.prepareAreaPlansInstance(this.item);
      },
    },
    resetEditingInstanceTrigger: "cancel",
  },
  methods: {
    prepareAreaPlansInstance(item) {
      if (
        item &&
        item.deviceSettings &&
        item.deviceSettings.areaPlans &&
        item.deviceSettings.areaPlans.length
      ) {
        const instance = _.cloneDeep(item.deviceSettings.areaPlans);
        instance.forEach((areaPlans) => {
          if (!areaPlans) {
            areaPlans = [];
          } else {
            areaPlans = instance.map((key, value) => ({
              key: parseFloat(value),
              id: _.uniqueId(),
            }));
          }
        });
        return instance;
      } else {
        return [];
      }
    },
    updateDelayField(index) {
      if (this.instance.length) {
        if (Number(this.instance[index].delay) === 0) {
          this.instance[index].delay = -1;
        }
      }
    },
    updateSpeedField(index) {
      if (this.instance.length) {
        const k = this.item.deviceStatus.depth_k;

        if (Number(this.instance[index].depth) < k) {
          this.instance[index].depth = k;
        }

        if (Number(this.instance[index].depth) > k * 100) {
          this.instance[index].depth = k * 100;
        }

        this.instance[index].speed = Math.floor(k / (Number(this.instance[index].depth) || 1) * 10000) / 100
      }
    },
    updateInstanceField(index) {
      if (this.instance.length) {

        if (
          Number(this.instance[index].end) <= Number(this.instance[index].start)
        ) {
          this.instance[index].end = Number(this.instance[index].start) + 10;
        }

        let i = 1;
        
        if (this.instance[index + i] !== undefined) {
          
          if (this.instance[index + i].hasOwnProperty('delay')) {
            this.instance[index + i].start = Number(this.instance[index].end) - 1;
            this.instance[index + i].end = Number(this.instance[index].end);

            i = 2;
          }

          this.instance[index + i].start = this.instance[index].end;
        }

        i = 1;

        if (this.instance[index - i] !== undefined) {
          if (this.instance[index - i].hasOwnProperty('delay')) {
            this.instance[index - i].start = Number(this.instance[index].start) - 1;
            this.instance[index - i].end = Number(this.instance[index].start);

            i = 2;
          }

          this.instance[index - i].end = this.instance[index].start;
        }

        if (Number([...this.instance].pop().end) !== this.endAngle) {
          this.addingInstance = true;
        }
      }
    },
    prepareAreaPlansFormData(data) {
      if (!data || !data.length) {
        return [];
      }
      // sort and fix
      // fill empty segment
      this.addAreaPlan();
      data = _.cloneDeep(data);

      // if (data[0].start !== 0) {
      //   data[0].start = 0
      // }

      // data.forEach((areaPlans, i) => {
      //   if (data[i + 1] !== undefined) {
      //       if (areaPlans.end !== data[i + 1].start) {
      //         data[i].end = data[i + 1].start
      //       }
      //   }
      //   else {
      //     if (areaPlans.end !== this.endAngle) {
      //       data[i].end = this.endAngle
      //     }
      //   }
      // })

      data.forEach((areaPlans) => {
        if (areaPlans && !areaPlans.length) {
          // delete areaPlans
        } else {
          areaPlans = areaPlans.map((key, value) => ({
            key: value.toString(),
          }));
        }
      });
      return data;
    },
    addDelay() {
      this.addingDelay = false;

      let newRow = {
        start: 0,
        end: 0,
        irrigation: "off",
        acc: "off",
        delay: 5
      };

      if (this.instance.length) {
        newRow.start = [...this.instance].pop().end - 1;
        newRow.end = [...this.instance].pop().end;
      }

      this.instance.push(newRow);
    }, 
    addAreaPlan() {
      this.addingInstance = false;
      this.addingDelay = true;

      let newRow = {
        // id: _.uniqueId(),
        start: this.startAngle,
        end: this.endAngle,
        irrigation: "off",
        acc: "off",
        speed: 100,
        depth: this.item.deviceStatus.depth_k
      };
      
      if (this.instance.length) {
        if (Number([...this.instance].shift().start) !== this.startAngle) {
          newRow.end = this.instance.length
            ? [...this.instance].shift().start
            : this.endAngle;
          this.instance.unshift(newRow);
        } else if (Number([...this.instance].pop().end) !== this.endAngle) {
          newRow.start = this.instance.length
            ? [...this.instance].pop().end
            : this.startAngle;
          this.instance.push(newRow);
        }

        if (Number([...this.instance].pop().end) !== this.endAngle) {
          this.addingInstance = true;
        }
      } else {
        this.instance.push(newRow);
      }
    },
    removeAreaPlan(index) {
      this.addingInstance = true;
      this.addingDelay = true;

      if (this.instance.length) {
        let i = 1;
        if (this.instance[index + i] !== undefined && this.instance[index + i].hasOwnProperty('delay')) {
          i = 2;
        }
        this.instance.splice(index, i);
        if (
          this.instance.length &&
          Number([...this.instance].pop().end) !== this.endAngle
        ) {
          this.addingInstance = true;
        }

        if ([...this.instance].pop().hasOwnProperty('delay')) {
          this.addingDelay = false;
        }
      }
    },
    start() {
      this.editingInstance = true;
      this.addingInstance = true;
      this.addingDelay = true;

      if (
        this.instance.length &&
        Number([...this.instance].pop().end) === this.endAngle
      ) {
        this.addingInstance = false;
      }
    },
    save() {
      this.setLoading(true);
      this.addAreaPlan();
      const data = this.prepareAreaPlansFormData(this.instance);
      Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: "rtu.updateAreaPlans",
            data: { deviceId: this.id, data },
          })
        )
        .then(() => this.$store.dispatch("rtu/fetch"))
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t("success.areaPlansUpdated"),
          });
          this.cancel();
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });

      return this.instance;
    },
    cancel() {
      this.instance = this.prepareAreaPlansInstance(this.item);
      this.editingInstance = false;
      this.addingInstance = false;
      this.addingDelay = false;
    },
    request() {
      this.setLoading(true);
      Promise.resolve()
        .then(
          () =>
            new Promise((resolve, reject) => {
              this.$emit("request", {
                type: "areaPlan",
                callback: resolve,
              });
            })
        )
        .finally(() => {
          this.setLoading(false);
        });
    },
  },
};
</script>
<style scoped>
.area_plan_settings .fc-map__table-cell {
  padding-left: 0;
}
</style>