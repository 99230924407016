<template>
  <div class="fc-map__page-body">
    <div class="body-1">
      <v-row>
        <v-col cols="12" sm="5">
          <map-item :item="mapItem" :icon-mode="{size: 260, lineWidth: 1}" class="fc-map__map-item" />
          <v-row class="justify-space-between">
            <v-col cols="auto">
              <div class="mb-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ timeUntilServiceStop }}</span>
                  </template>
                  <span>{{ $t('timeUntilServiceStop') }}</span>
                </v-tooltip>
                <span v-if="type === 'lateral'"> (<v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ timeUntilEndFieldWithHoseStop }}</span>
                  </template>
                  <span>{{ $t('timeUntilEndFieldWithHoseStop') }}</span>
                </v-tooltip>)</span>
              </div>            
              <div>
                <span v-for="(gun, index) in (item.deviceSettings.endGuns || [])" :key="gun.id" class="fc-gun-icon mr-3">
                  <img :src="require('~/assets/icons/icon-gun-' + ((gun.active === 'on') ? 'active' : gun.status) + '.svg')" width="20">
                  <span class="fc-gun-icon__number">
                    {{ index + 1}}
                  </span>
                </span>
              </div>
            </v-col>
            <v-col v-if="type === 'lateral'" cols="auto">
              {{ item.deviceStatus.currentPosition}} {{ $t('form.radiusSuffix') }}
            </v-col>
            <v-col v-else cols="auto">
              <img :src="require('~/assets/icons/icon-angle.svg')" width="20">
              {{ item.deviceStatus.currentAngle }}°
            </v-col>
          </v-row>
          <div class="caption grey--text">
            {{ item.manufacturer }}
          </div>
          <div v-if="item.organization && item.organization.name" class="caption grey--text">
            {{ item.organization.name }}
          </div>
        </v-col>
        <v-col cols="12" sm="7">
          <!--
          <item-alert v-if="item && item.deviceStatus && (item.deviceStatus.error || item.deviceStatus.warning)" :item="item" mode="title" class="mt-3 mb-0" />
          -->
          <div class="title">
            {{ $t('status.' + itemStatus.message) }}
          </div>
          <div>
            {{ capitalize(formatDateTimeFromNow(item.deviceStatus.lastUpdate)) }}
            <!-- ({{ formatDateTime(item.deviceStatus.lastUpdate) }}) -->
          </div>
          <div v-if="noAreaPlan">
            {{ $t('workPlan.' + item.deviceSettings.workPlan.type) }}
          </div>
          <v-row v-if="!noAreaPlan">
            <v-col cols="12" sm="6">
              <field-control v-model="instance['deviceSettings.workPlan.mode']" :field="modeField" :disabled="loading || isReadOnly" @change="changeMode"/>
            </v-col>
          </v-row>
          <div>
            <fields v-model="instance" :fields="fields" :disabled="loading || isReadOnly || isAutoMode || isFcWatch" @updateField="updateInstanceField" />
          </div>
          <div v-if="!isFcWatch">
            <item-icon-controls v-model="instance" :disabled="loading || isReadOnly || isFcWatch" :autoMode="isAutoMode" col-class="d-flex justify-space-between align-center" />
          </div>
          <div v-if="isFcWatch && type === 'lateral'">
            <item-icon-controls-fcwatch-lateral v-model="instance" :disabled="loading || isReadOnly || isFcWatch" :autoMode="isAutoMode" col-class="d-flex justify-space-between align-center" />
          </div>
        </v-col>
        <v-col cols="12">
          <field-control v-model="instance['deviceSettings.workPlan.comment']" :field="commentField" :disabled="loading || isReadOnly  || isFcWatch || isAutoMode" @change="handleChange"/>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-3" v-if="!isReadOnly">
      <v-col class="d-flex flex-wrap justify-end">
        <v-btn v-if="!editingInstance && !isFcWatch" :loading="loading" class="mr-3" text @click="request">{{ $t('actions.request') }}</v-btn>
        <v-btn v-if="editingInstance" :loading="loading" class="mr-3" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
        <v-btn v-if="!(isFcWatch && type !== 'lateral')" :loading="loading" :disabled="!editingInstance || (isFcWatch && type !== 'lateral')" color="primary" @click="save">{{ $t('actions.apply') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MapItem from '~/components/map/MapItem'
import ItemIconControls from '~/components/map/ItemIconControls2'
import ItemIconControlsFcwatchLateral from '~/components/map/ItemIconControls4'
import configFields from '~/config/fields'
import configControls from '~/config/controls'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import {capitalize} from '~/utils/time'
import {getItemStatus} from '~/utils/status'

export default {
  components: {MapItem, ItemIconControls, ItemIconControlsFcwatchLateral},
  mixins: [generalFormMixin, dateTimeFormat],
  props: ['id', 'tab'],
  data () {
    return {
      instance: {},
      // locale: {
      //   'Авто': this.$t('workPlan.auto'),
      //   'Ручное управление': this.$t('workPlan.manual'),
      // },
      editingInstance: false,
      autoMode: false
    }
  },
  computed: {
    user () { return this.$store.state.auth.user },
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    isUser () { return this.$store.getters['auth/isUser'] },
    isReadOnly () { return this.$store.getters['auth/isReadOnly'] },
    type () { return this.item?.displaySettings?.type || 'circle' },
    isDiesel () { return this.item.relays ? true : false },
    isBauer () { return this.item.deviceSettings.workPlan.circles ? true : false },
    isFcWatch () { return this.item.manufacturer === 'FC Watch' ? true : false },
    isFC() { return (this.item.manufacturer === "FC" && (this.item.organization.name === 'Кобзев')) ? true : false },
    isAutoMode () { return this.autoMode },
    noAreaPlan() { return !this.item.deviceSettings.areaPlans || _.isEmpty(this.item.deviceSettings.areaPlans) },
    fields () { return configFields.item.filter(field => {
      if (this.isDiesel) {
        return (!field.forItemType || field.forItemType === this.type) && !field.bauer && !field.isFC && (field.diesel !== false || field.diesel && field.diesel === this.isDiesel)
      } 
      else if (this.isBauer) {
        return (!field.forItemType || field.forItemType === this.type) && !field.diesel && (field.bauer !== false || field.bauer && field.bauer === this.isBauer)
      }
      else if (this.isFC) {
        return (!field.forItemType || field.forItemType === this.type) && !field.diesel && !field.bauer && (field.fc !== false || field.fc && field.fc === this.isFC)
      }
      else {
        return (!field.forItemType || field.forItemType === this.type) && !field.diesel && !field.bauer && !field.fc
      }
      
      // return (((!field.forItemType) || field.forItemType === this.type) || field.diesel === true) 
    }) 
    },
    modeField () { return configFields.item.find(field => field.key === 'deviceSettings.workPlan.mode') },
    // modeField () { 
    //   return configFields.item.find(field => {
    //     if (field.key === 'deviceSettings.workPlan.mode') {
    //       field.dataSource.forEach((val, i) => {
    //         field.dataSource[i] = this.locale[val] ? this.locale[val] : val
    //       })
          
    //       this.instance['deviceSettings.workPlan.mode'] = this.locale[this.instance['deviceSettings.workPlan.mode']]
    //       return field
    //     }
    //   }) 
    // },
    commentField () { return configFields.item.find(field => field.key === 'deviceSettings.workPlan.comment') },
    controls () { return configControls },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    itemStatus () { return getItemStatus(this.item) },
    areaPlansPoints () { return (!this.noAreaPlan && this.autoMode) ? this.item.deviceSettings.areaPlans.map(plan => Number(plan.end)) : []},
    stopPoints () {
      return ([Number(this.item.deviceSettings.startAngle), Number(this.item.deviceSettings.endAngle), Number(this.item.deviceSettings.workPlan.startAngle), ...this.areaPlansPoints])
      // .filter(n => n)
    },
    minStopPoint() {
      return Math.min(...this.stopPoints)
    },
    maxStopPoint() {
      return Math.max(...this.stopPoints)
    },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item, editingInstance: this.editingInstance})
    },
    resetEditingInstanceTrigger () {
      return this.id + this.tab
    },
    setEditingInstanceTrigger () {
      return JSON.stringify({instance: this.instance, item: this.item})
    },
    fullCircleTime () {
      // return Math.floor(this.item.deviceSettings.fullCircleTime / 60 * 10) / 10 // Зачем..?
      return Math.floor(this.item.deviceSettings.fullCircleTime / 60)
    },
    timeUntilServiceStop () {
      return this.timeUntilStop()
    },
    timeUntilEndFieldWithHoseStop () {
      return this.timeUntilStop(this.item.deviceSettings.fullCircleTimeWithHoseStops)
    },
    lateralEndPosition () {
      return Number(this.item.displaySettings.endPosition) || 0      
    },
    mapItem () {
      const data = ((this.prepareFormSubmitData(this.instance, this.fields) || {}).deviceSettings || {}).workPlan || {}
      this.controls.forEach((control) => {
        data[control.key] = this.instance[control.key]
      })
      return {
        ...this.item,
        deviceStatus: {
          ...this.item.deviceStatus,
          serviceStop: this.instance['deviceSettings.workPlan.serviceStop'],
          serviceStop2: this.instance['deviceSettings.workPlan.serviceStop2'],
          move: this.instance.move
        }
      }
    }
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.preparePrimaryInstance(this.item)
        this.autoMode = this.instance['deviceSettings.workPlan.mode'] === 'Авто' ? true : false
      }
    },
    resetEditingInstanceTrigger: 'cancel',
    setEditingInstanceTrigger () {
      const readonlyFieldsRev = this.fields.reduce((out, field) => {
        if (field.disabled || (field.inputOptions || {}).disabled || field.key == 'deviceSettings.workPlan.comment') {
          out[field.key] = true
        }
        return out
      }, {})
      const basicInstance = this.preparePrimaryInstance(this.item)
      Object.keys(this.instance).forEach((key) => {
        if (!readonlyFieldsRev[key] && (this.instance[key] !== basicInstance[key])) {
          // console.log('setEditingInstance', key, this.instance[key], basicInstance[key])
        }
      })
      this.editingInstance = !!Object.keys(this.instance).find((key) => {
        return !readonlyFieldsRev[key] && (this.instance[key] !== basicInstance[key])
      })
    }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    capitalize,
    timeUntilStop (runTimeWithHoseStop = null) {
      if (this.instance.move === 'stop' 
      || isNaN(Number(this.instance['deviceSettings.workPlan.serviceStop']) 
      || isNaN(Number(this.item.deviceSettings.fullCircleTime)) 
      || isNaN(Number(this.item.deviceStatus.currentAngle))
      || isNaN(Number(this.item.deviceStatus.currentPosition)))
      
      || (this.type == 'circle' && !(this.item.deviceStatus.currentAngle >= this.minStopPoint && this.item.deviceStatus.currentAngle <= this.maxStopPoint))
      ) {
        return '---'
      } 
      if (this.type == 'circle') {
        let angleDiff = 0

        if ((Number(this.item.deviceSettings.startAngle) === 0 && Number(this.item.deviceSettings.endAngle) === 360) && !this.item.deviceSettings.areaPlans) {
          angleDiff = (this.instance.move === 'frw' ? -1 : 1) * (this.item.deviceStatus.currentAngle - (this.instance['deviceSettings.workPlan.serviceStop'] !== null ? this.instance['deviceSettings.workPlan.serviceStop'] : this.instance['deviceSettings.workPlan.startAngle']))
        } else {
          let nextStop = 0
          let degs = []

          if (this.instance.move === 'frw') {
            
            if (this.instance['deviceSettings.workPlan.serviceStop'] !== null && this.item.deviceStatus.currentAngle < this.instance['deviceSettings.workPlan.serviceStop']) {
              degs.push(this.instance['deviceSettings.workPlan.serviceStop'])
            }
            degs.push(...this.stopPoints.filter(next_point => next_point > this.item.deviceStatus.currentAngle))
            if (degs.length == 0)
              degs.push(Number(this.item.deviceSettings.endAngle))

            nextStop = Math.min(...degs)
          } 
          else if (this.instance.move === 'rev') {
            if (this.instance['deviceSettings.workPlan.serviceStop'] !== null && this.item.deviceStatus.currentAngle > this.instance['deviceSettings.workPlan.serviceStop']) {
              degs.push(this.instance['deviceSettings.workPlan.serviceStop'])
            }
            
            degs.push(...this.stopPoints.filter(next_point => next_point < this.item.deviceStatus.currentAngle))
            
            if (degs.length == 0)
              degs.push(Number(this.item.deviceSettings.startAngle))

            nextStop = Math.max(...degs)
          }
  
          if (!(Number(this.item.deviceSettings.startAngle) === 0 && Number(this.item.deviceSettings.endAngle) === 360 && !this.item.deviceSettings.areaPlans)) {
            angleDiff = (this.instance.move === 'frw' ? -1 : 1) * (this.item.deviceStatus.currentAngle - nextStop)          
          }
        }

        let fullCircleTime = this.item.deviceSettings.settings['Circle Time'] ?? this.item.deviceSettings.settings['Full Circle Time']

        // let fullCircleTime = this.item.deviceSettings.fullCircleTime;

        // console.log(fullCircleTime)

        // angleDiff = (this.instance.move === 'frw' ? -1 : 1) * (this.item.deviceStatus.currentAngle - (this.instance['deviceSettings.workPlan.serviceStop'] !== null ? this.instance['deviceSettings.workPlan.serviceStop'] : this.instance['deviceSettings.workPlan.startAngle']))

        // console.log(angleDiff,this.instance['deviceSettings.workPlan.startAngle'])
        
        while (angleDiff < 0) { angleDiff += 360 }
        while (angleDiff > 360) { angleDiff -= 360 }
        // console.log(angleDiff)

        let timeMin = Math.floor(fullCircleTime * angleDiff / 360 * 100 / (this.instance['deviceSettings.workPlan.speed'] || 1))
        // console.log(timeMin)


        const timeH = Math.floor(timeMin / 60)
        
        timeMin -= timeH * 60
        let out = []
        // if (timeH) {
        //   out.push(timeH + ' ' + this.$t('form.timeSuffixHour'))
        // }
        // if (timeMin) {
        //   out.push(timeMin + ' ' + this.$t('form.timeSuffixMinute'))
        // }
        // return out.join(' ')

        // if (timeH) {
          out.push((timeH < 10) ? '0' + timeH : timeH)
        // }
        // if (timeMin) {
          out.push(((timeMin < 10) ? '0' + timeMin : timeMin) + ' ' + this.$t('form.timeSuffixHour'))
        // }

        return out.join(':')
      }

      else if (this.type == 'lateral') {

        let length = Number(this.item.deviceSettings.length);
        
        length = (this.lateralEndPosition > 0 && this.lateralEndPosition > this.item.deviceStatus.currentPosition) ? this.lateralEndPosition : Number(this.item.deviceSettings.length)   

        if (runTimeWithHoseStop === null) {
          if (this.instance.move === 'rev') {    
            let e = 0
            if (this.lateralEndPosition > 0 && this.lateralEndPosition < this.item.deviceStatus.currentPosition && this.instance['deviceSettings.workPlan.serviceStop'] < this.item.deviceStatus.currentPosition) {
              e = Math.max(...[this.instance['deviceSettings.workPlan.serviceStop'], this.lateralEndPosition].filter(Boolean)) 
            }
            else if (this.instance['deviceSettings.workPlan.serviceStop'] > 0 && this.instance['deviceSettings.workPlan.serviceStop'] < this.item.deviceStatus.currentPosition) {
              e = this.instance['deviceSettings.workPlan.serviceStop']
            }
            length -= (length - e)
          }

          if (this.instance.move === 'frw') {
            if (this.lateralEndPosition > this.item.deviceStatus.currentPosition && this.instance['deviceSettings.workPlan.serviceStop'] > this.item.deviceStatus.currentPosition) {
            // find min exclude 0
              length = Math.min(...[this.instance['deviceSettings.workPlan.serviceStop'], this.lateralEndPosition].filter(Boolean))
            } 
            else if (this.instance['deviceSettings.workPlan.serviceStop'] > this.item.deviceStatus.currentPosition && this.item.deviceStatus.currentPosition > length) {
              length = Math.max(...[this.instance['deviceSettings.workPlan.serviceStop'], length].filter(Boolean))
            }
            else if (this.instance['deviceSettings.workPlan.serviceStop'] > this.item.deviceStatus.currentPosition && this.instance['deviceSettings.workPlan.serviceStop'] < length) {
              length = Math.min(...[this.instance['deviceSettings.workPlan.serviceStop'], length].filter(Boolean))
            } 
          }
        }


        if (runTimeWithHoseStop !== null && this.instance.move === 'rev') {
          length = (this.lateralEndPosition > 0 && this.lateralEndPosition < this.item.deviceStatus.currentPosition) ? this.lateralEndPosition : 0
        }
        
        let diff = (this.instance.move === 'rev' ? -1 : 1) * (length - this.item.deviceStatus.currentPosition)
        
        if (this.instance.move === 'rev' && (isNaN(Number(this.instance['deviceSettings.workPlan.serviceStop'])) || this.instance['deviceSettings.workPlan.serviceStop'] > this.item.deviceStatus.currentPosition)) {
          diff += length

          if ((Number(this.instance['deviceSettings.workPlan.serviceStop']) > this.lateralEndPosition && this.lateralEndPosition < this.item.deviceStatus.currentPosition)) {
            diff -= this.lateralEndPosition
          }
        }


        let timeMin = Math.floor(this.item.deviceSettings.fullCircleTime * diff / this.item.deviceSettings.length * 100 / (this.instance['deviceSettings.workPlan.speed'] || 1))
      

        if (runTimeWithHoseStop !== null) {
          timeMin += runTimeWithHoseStop - this.item.deviceSettings.fullCircleTime
        }


        timeMin = timeMin < 0 ? 0 : timeMin
        
        const timeH = Math.floor(timeMin / 60)

        timeMin -= timeH * 60

        let out = []

        
        // if (timeH) {
          out.push((timeH < 10) ? '0' + timeH : timeH)
        // }
        // if (timeMin) {
          out.push(((timeMin < 10) ? '0' + timeMin : timeMin) + ' ' + this.$t('form.timeSuffixHour'))
        // }

        return out.join(':')
      } 
      return;
    },
    preparePrimaryInstance (item) {
      if (item) {
        const controlFields = this.controls.reduce((out, control) => {
          out[control.key] = item.deviceSettings.workPlan[control.key]
          return out
        }, {})
        const out = {
          ...this.prepareInstance(item, this.fields, true),
          ...controlFields
        }

        // let timeMin = item.deviceSettings.fullCircleTime
        // const timeDecimal = Math.floor(fullCircleTime / (out['deviceSettings.workPlan.speed'] || 1) * 10000) / 100

        // const time = this.decimalToTime(timeDecimal)
        // out['deviceSettings.workPlan.time'] = (time).split(':')[0] + ':' + (time).split(':')[1]
        // out['deviceSettings.workPlan.time'] = time
        
        let timeMin = Math.floor(item.deviceSettings.fullCircleTime / (out['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        let timeH = Math.floor(timeMin / 60)
        timeMin -= timeH * 60
        timeMin = Math.floor(timeMin)
        timeMin = (timeMin < 10) ? '0' + timeMin : timeMin

        let fullTime = Number(String(timeH) + String(timeMin)) / 100
        out['deviceSettings.workPlan.time'] = fullTime
        
        return out
      } else {
        return {}
      }
    },
    updateInstanceField ({key, value}) {
      this.$nextTick(() => {
        const k = this.item.deviceStatus.depth_k
        
        // let fullCircleTime = this.item.deviceSettings.settings['Full Circle Time']


        // let timeMin = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100

        // let timeH = Math.floor(timeMin / 60)
        // timeMin -= timeH * 60
        // timeMin = Math.floor(timeMin)
        // timeMin = (timeMin < 10) ? '0' + timeMin : timeMin

        // let fullTime = Number(String(timeH) + String(timeMin)) / 100
        // console.log(fullTime)

        if (key === 'deviceSettings.workPlan.speed') {
          this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.time'] = this.fullTime((this.instance['deviceSettings.workPlan.speed'] || 1))
        } else if (key === 'deviceSettings.workPlan.depth') {
          this.instance['deviceSettings.workPlan.speed'] = Math.floor(k / (this.instance['deviceSettings.workPlan.depth'] || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.time'] = this.fullTime((this.instance['deviceSettings.workPlan.speed'] || 1))
        } 
        else if (key === 'deviceSettings.workPlan.time') {
          let fullCircleTime = this.item.deviceSettings.fullCircleTime;
          let timeMin = this.timeToMin((this.instance['deviceSettings.workPlan.time'] || 1))          

          this.instance['deviceSettings.workPlan.speed'] = Math.floor(fullCircleTime / (timeMin || 1) * 10000) / 100
          this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        }

        // const fullCircleTime = this.item.deviceSettings.fullCircleTime / 60
        
        // if (key === 'deviceSettings.workPlan.speed') {          
        //   const timeDecimal = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        //   const time = this.decimalToTime(timeDecimal)
          
        //   this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        //   // this.instance['deviceSettings.workPlan.time'] = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        //   this.instance['deviceSettings.workPlan.time'] = time
          
        // } else if (key === 'deviceSettings.workPlan.depth') {
        //   this.instance['deviceSettings.workPlan.speed'] = Math.floor(k / (this.instance['deviceSettings.workPlan.depth'] || 1) * 10000) / 100

        //   const timeDecimal = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        //   const time = this.decimalToTime(timeDecimal)

        //   // this.instance['deviceSettings.workPlan.time'] = Math.floor(fullCircleTime / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        //   this.instance['deviceSettings.workPlan.time'] = time
        // } else if (key === 'deviceSettings.workPlan.time') {
        //   const timeDecimal = this.timeToDecimal(this.instance['deviceSettings.workPlan.time'])
        //   this.instance['deviceSettings.workPlan.speed'] = Math.floor(fullCircleTime / (timeDecimal || 1) * 10000) / 100
        //   this.instance['deviceSettings.workPlan.depth'] = Math.floor(k / (this.instance['deviceSettings.workPlan.speed'] || 1) * 10000) / 100
        // }
      })
    },
    changeMode () {
      if (this.instance['deviceSettings.workPlan.mode'] === 'Авто') {
        this.autoMode = true
      } else if (this.instance['deviceSettings.workPlan.mode'] === 'Ручное управление') {
        this.autoMode = false
      }
    },
    handleChange (value) {
      this.setLoading(true)
      this.resetAlert()
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateComment', data: {id: this.id, comment: value}}))
        .then(() => this.$store.dispatch('rtu/fetch'))
        // .then(() => {
        //   this.$swal({icon: 'success', text: this.$t('success.settingsUpdated')})
        //   this.opened = false
        // })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel () {
      this.instance = this.preparePrimaryInstance(this.item)
    },
    save () {
      this.setLoading(true)
      const data = ((this.prepareFormSubmitData(this.instance, this.fields) || {}).deviceSettings || {}).workPlan || {}
      this.controls.forEach((control) => {
        data[control.key] = this.instance[control.key]
      })
      const command = this.prepareCommand(data)
      command.id = this.item.id
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.cmd', data: command}))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 500)
        }))
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.id', data: {id: this.item.id}}))
        .then((response) => {
          let data = response.data.map(function(obj){
            return {...obj, time: Date.now()};
          })
          return this.$store.dispatch('rtu/updateEditingItem', data)
        })
        // .then(() => {
        //   this.$swal({icon: 'success', text: this.$t('success.cmdSent')})
        // })
        .then(() => {
          this.$snack({type: 'success', message: this.$t('success.cmdSent')})
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    prepareCommand (data) {
      const command = {}
      command.move = data.move
      command.speed = parseFloat(data.speed).toFixed(2)
      command.depth = parseFloat(data.depth).toFixed(2)
      command.irrigation = data.irrigation === 'off' ? 'stop' : 'start'
      command.acc = data.acc === 'off' ? 'stop' : 'start'
      command.accMixer = data.accMixer === 'on' ? 'start' : 'stop'
      command.autorev = data.autoRev === 'off' ? 'stop' : 'start'
      command.servicestop = data.serviceStop
      command.servicestop2 = data.serviceStop2 ?? null
      
      command.circles = data.circles
      command.parking = !!data.parking
      command.parkingswitch = !!data.parkingSwitch
      command.servicestoprepeat = !!data.serviceStopRepeat
      command.servicestopswitch = !!data.serviceStopSwitch
      command.autostart = data.autoStart === 'off' ? 'stop' : 'start'
      // command.comment = data.comment
      command.mode = data.mode === 'Авто' ? 'auto' : 'manual'
      return command
    },
    fullTime(value) {
      let fullCircleTime = this.item.deviceSettings.fullCircleTime;
      let timeMin = Math.floor(fullCircleTime / (value || 1) * 10000) / 100
      let timeH = Math.floor(timeMin / 60)
      timeMin -= timeH * 60
      timeMin = Math.floor(timeMin)
      timeMin = (timeMin < 10) ? '0' + timeMin : timeMin
      return Number(String(timeH) + String(timeMin)) / 100
    },
    decimalToTime(value) {
      const hours = Math.round(value)

      const denominator = (value % 1).toFixed(2)

      const minDecimal = (denominator * 60).toFixed(1)
      const min = (Math.round(minDecimal) < 10) ? '0' + Math.round(minDecimal) : Math.round(minDecimal)
      // const sec = (minDecimal % 1).toFixed(2) * 60

      return hours + ':' + min
    },
    timeToDecimal(value) {
      let timeH = Math.round(value)
      let timeMin = ((value % 1).toFixed(2)) * 100
      timeMin = (timeMin / 60).toFixed(2)

      // const min = (Math.round(minDecimal) < 10) ? '0' + Math.round(minDecimal) : Math.round(minDecimal)
      // // const sec = (minDecimal % 1).toFixed(2) * 60

      // // return hours + ':' + min

      return Number(timeH) + Number(timeMin)
    },
    timeToMin(value) {
      let timeH = Math.round(value)
      let timeMin = ((value % 1).toFixed(2)) * 100
      timeH = timeH * 60

      // const min = (Math.round(minDecimal) < 10) ? '0' + Math.round(minDecimal) : Math.round(minDecimal)
      // // const sec = (minDecimal % 1).toFixed(2) * 60

      // // return hours + ':' + min

      return Number(timeH) + Number(timeMin)
    },
    // _timeToDecimal(value) {

    //   let time = moment(value, 'H:m').utc(true).utcOffset(3)
    //   return time.hour() * 60 + time.minute()
    //   // if (!isNaN(value)) {
        
    //       // let hours = parseInt(value.split(':')[0])
    //       // let min = parseInt(value.split(':')[1])
    //       // // // if (isNaN(min)){
    //       // // //   console.log(11111)
    //       // // //   min = 0
    //       // // // }
          
    //       // // min = (min < 10) ? parseInt(min + '0') : min
    //       // // console.log(hours,min)
    
    //       // return hours * 60 + min
    //       // const minDecimal = ((min / 60) * 10).toFixed(1)
    //       // // const secDecimal = Math.round((value.split(':')[2] / 3600) * 10)
    //       // return parseFloat(hours + '.' + minDecimal)
    //   // }
    // },
    request () {
      this.setLoading(true)
      Promise.resolve()
        .then(() => new Promise((resolve, reject) => {
          this.$emit('request', {
            type: 'main',
            callback: resolve
          })
        }))
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
